h2, h5, h6 {
	font-weight: bold;
	margin: 0;
}
.fs-12 {
	font-size: 12px;
}
.fs-14 {
	font-size: 14px;
}
.fs-16 {
	font-size: 16px;
}
.text-normal {
	font-weight: normal;
}
.text-bold {
	font-weight: bold !important;
}
.lh-20 {
	line-height: 20px;
}
.lh-24 {
	line-height: 24px;
}
.lh-28 {
	line-height: 28px;
}
.miw-120 {
	min-width: 120px;
}
.miw-160 {
	min-width: 160px;
}
.miw-200 {
	min-width: 200px;
}
.miw-240 {
	min-width: 240px;
}
.miw-380 {
	min-width: 380px;
}
.mah-450 {
	max-height: 450px;
}
.gap-8 {
	gap: 8px !important;
}
.gap-16 {
	gap: 16px !important;
}
.gap-20 {
	gap: 20px !important;
}
.gap-28 {
	gap: 28px !important;
}
.bg-danger {
	background-color: $danger !important;
}
.flex-1 {
	flex: 1 0 0;
}
.cursor-default {
	cursor: default !important;
}
input[type=file]::-webkit-file-upload-button {
	cursor: pointer;
}
.page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
.main-header {
	background: #FFF;
	border-bottom: 1px solid $line-gray;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
	height: 72px;
	padding-left: 267px;
	transition: padding-left 0.3s ease;
}
.sidenav-toggled .main-header {
	padding-left: 64px;
	transition: padding-left 0.3s ease;
}
.side-header {
	position: fixed;
	display: flex;
	width: 100%;
	z-index: 999;
}
.main-header > .container-fluid {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 100%;
	padding: 0px 56px;
	gap: 88px;
	@media (max-width: $screen-sm) {
		padding: 0 10px;
	}
}
.app-sidebar {
	position: fixed;
	width: 266px;
	max-height: 100%;
	background: #fff;
	border-right: 1px solid $line-gray;
	transition: width 0.3s ease;
	z-index: 999;
}
.main-sidebar-header {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	height: 72px;
	width: 266px;
	background: #fff;
	border-right: 1px solid $line-gray;
	transition: width 0.3s ease;
	z-index: 999;
}
.main-sidebar-header .desktop-logo {
	height: 38px;
}
.main-sidebar-header .logo-icon {
	display: none;
}
.main-sidemenu {
	display: flex;
	flex-direction: column;
	margin-top: 72px;
	padding: 28px 0 172px;
	gap: 40px;
}
.main-sidebar-footer {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	bottom: 0;
	gap: 28px;
	padding: 15px 20px;
	width: 266px;
	background: #fff;
	border-right: 1px solid $line-gray;
	transition: width 0.3s ease;
	z-index: 900;
}
.main-sidebar-footer button {
	justify-content: center;
	align-items: center;
	padding: 1rem 0 !important;
	border-radius: 8px !important;
	border: 1px solid $line-gray;
	color: $danger;
}
.main-sidebar-footer button:hover {
	border-color: $danger;
	color: $danger;
}
.main-sidebar-footer button > span, .side-menu .side-menu__item > span, .sidemenu-list .nav-link span {
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
}
.main-sidebar-footer > div {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: $secondary;
	gap: 8px;
	width: 100%;
	cursor: pointer;
}
.app-content {
	min-height: calc(100vh - 60px);
	transition: margin-left 0.3s ease;
}
.main-content {
	margin-left: 266px;
	padding-top: 72px;
}
.main-content .container-fluid {
	display: flex;
	flex-direction: column;
	padding: 1rem 20px 40px;
	gap: 20px;
	@media (max-width: $screen-sm) {
		padding: 4px 4px 10px;
		gap: 10px;
	}
}
.main-footer .container-fluid {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 14px;
	padding: 8px 60px 8px calc(266px + 60px);
	gap: 4px;
	background-color: $primary;
	color: #FFF;
	line-height: 20px;
	transition: padding 0.3s ease;
}
.sidenav-toggled .main-footer .container-fluid {
	padding: 8px 60px 8px calc(64px + 60px);
}
.main-footer a {
	color: #FFF;
}
.sidenav-toggled .main-sidebar-footer {
	padding: 15px 4px;
}
.sidenav-toggled .app-sidebar, .sidenav-toggled .main-sidebar-header, .sidenav-toggled .main-sidebar-footer {
	width: 64px;
}
.sidenav-toggled .main-sidebar-footer > div {
	justify-content: center;
}
.sidenav-toggled .main-sidebar-footer > div svg {
	transform: rotate(180deg);
}
.sidenav-toggled .main-sidebar-footer > div span, .sidenav-toggled .main-sidebar-footer button span, .sidenav-toggled .side-menu .side-menu__item > span, .sidenav-toggled .sidemenu-list .nav-link span {
	display: none;
}
.sidenav-toggled .app-content {
	margin-left: 64px;
}
.sidenav-toggled .desktop-logo {
	display: none;
}
.sidenav-toggled .logo-icon {
	display: block;
}
.ps > .ps__rail-y {
	width: 2px;
}
.ps > .ps__rail-y > .ps__thumb-y {
	width: 2px;
	inset-inline-start: 4px;
}
.side-menu, .sidemenu-list {
	padding: 0;
	list-style: none;
}
.sidemenu-list {
	display: none;
}
.sidemenu-list.open {
	display: block;
}
.side-menu .side-menu__item, .sidemenu-list .nav-link {
	display: flex;
	align-items: center;
	padding: 1rem 20px 1rem 1rem;
	gap: 8px;
	color: #000;
	border-left: 4px solid transparent;
}
.sidemenu-list .nav-link {
	color: $dark-gray;
}
.arrow-sidemenu {
	transition: transform 0.3s ease;
}
.sidenav-toggled .arrow-sidemenu {
	display: none;
}
.side-menu .side-menu__item.is-expanded .arrow-sidemenu {
	transform: rotate(180deg);
}
.side-menu .side-menu__item .icon {
	width: 24px;
}
.side-menu .side-menu__item.active, .side-menu .side-menu__item:hover, .sidemenu-list .nav-link.active, .sidemenu-list .nav-link:hover {
	color: $primary;
	border-color: transparent
}
.side-menu .side-menu__item.active {
	border-color: $primary;
}
.side-menu .side-menu__item.active img, .side-menu .side-menu__item:hover img, .sidemenu-list .nav-link.active img, .sidemenu-list .nav-link:hover img {
	filter: brightness(0) saturate(100%) invert(73%) sepia(66%) saturate(6410%) hue-rotate(161deg) brightness(97%) contrast(101%);
}
.arrow-sidemenu {
	position: absolute;
	right: 1rem;
	color: $primary;
}
.nav-menu {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
}
.nav-menu .nav-item {
	margin: 0;
}
.nav-menu .nav-item .btn.icon svg {
	color: $primary;
}
.nav-menu .nav-item .btn.icon:hover {
	background-color: $primary;
}
.nav-menu .nav-item .btn.icon:hover svg {
	color: #FFF;
}
.nav-menu .nav-item .btn.icon.account {
	display: flex;
	align-items: center;
	font-weight: bold;
	background-color: transparent;
	border: none;
	gap: 8px;
	padding: 4px;
}
.nav-menu .nav-item.show .btn.icon.account, .nav-menu .nav-item .btn.icon.account:hover, .nav-menu .nav-item .btn.icon.account:focus {
	background-color: transparent;
	color: #000;
}
.nav-menu .nav-item .btn.icon.account .account-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $surface;
	height: 40px;
	width: 40px;
	padding: 0;
}
.nav-menu .nav-item .btn.icon.account .account-icon svg {
	color: $dark-gray;
}
.breadcrumb-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
	border-bottom: 1px solid $line-gray;
}
.breadcrumb-header h3 {
	margin: 0;
	line-height: 36px;
}
.breadcrumb-action {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 1rem;
}
.card.card-body {
	border-radius: 8px;
	gap: 1rem;
}
.card > .title {
	border-bottom: 1px solid $line-gray;
	margin: 0;
	padding-bottom: 1rem;
	gap: 8px;
}
.card > .title.between {
	justify-content: space-between;
}
.card > .title.between > div {
	display: flex;
	align-items: center;
	gap: 8px;
}
.card > .title .back {
	cursor: pointer;
}
.card > .title .download {
	font-size: 14px;
	color: $secondary;
	cursor: pointer;
}
.card .action {
	display: flex;
	justify-content: flex-end;
}
.center-name {
	display: flex;
	align-items: center;
	gap: 28px;
}
.center-name > div:first-child {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 140px;
}
.center-name > div:last-child {
	flex: 1 0 0;
}
.center-name .logo {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: $dark-gray;
	color: #FFF;
	width: 84px;
	height: 84px;
}
.center-name .logo img, .list-pictures > div img, .list-items .item.booking .logo img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}
.center-name .add-logo {
	position: relative;
	display: flex;
	align-items: center;
	font-weight: bold;
	padding: 8px 12px;
	gap: 8px;
	color: $secondary;
}
.center-name .add-logo input, .rehab-detail .pic-add input, .sub-card .card-header .edit input[type="file"] {
	position: absolute;
	width: 100%;
	opacity: 0;
	cursor: pointer;
}
.center-name .add-logo .is-data {
	display: flex;
	gap: 8px;
	font-weight: normal;
	color: #000;
}
.center-name .logo.w-80 {
	width: 80px;
	height: 80px;
	flex: none;
}
.not-required {
	font-size: 14px;
	font-weight: normal;
	line-height: 20px;
	color: $light-gray;
	margin-left: 8px;
}
.react-tel-input.phone-input-ui .form-control {
	height: auto !important;
	margin: 0;
	padding: 0.5rem 1rem;
	border-radius: 8px;
	border-color: #EBEBEB;
	line-height: 24px;
}
.react-tel-input.phone-input-ui .form-control:focus {
	border-color: $light-gray;
}
.react-tel-input.phone-input-ui .selected-flag {
	display: none;
}
.react-tel-input.phone-input-ui .invalid-number-message {
	left: 1rem;
}
.form-control-select .css-13cymwt-control, .form-control-select .css-13cymwt-control:hover:hover {
	border-radius: 8px;
	border-color: #EBEBEB;
	line-height: 24px;
}
.form-control-select .css-t3ipsp-control:focus-within {
	border-radius: 8px;
	box-shadow: none;
	border-color: $light-gray;
}
.form-control-select .css-1fdsijx-ValueContainer {
	padding: 0.4rem 1rem;
}
.form-control-select .css-1xc3v61-indicatorContainer, .form-control-select .css-15lsz6c-indicatorContainer {
	padding: 7px;
}
// .form-control-select .css-1xc3v61-indicatorContainer svg {
// 	transition: transform 0.3s ease;
// }
// .form-control-select .css-15lsz6c-indicatorContainer svg {
// 	transition: transform 0.3s ease;
// 	transform: rotate(180deg);
// }
.form-control-select .css-qbdosj-Input, .form-control-select .css-166bipr-Input {
	margin: 0;
	padding: 0;
}
.form-control-select .css-1u9des2-indicatorSeparator {
	display: none;
}
.form-control-select .css-tj5bde-Svg {
	width: 24px;
	height: 24px;
	filter: brightness(0) saturate(100%) invert(73%) sepia(66%) saturate(6410%) hue-rotate(161deg) brightness(97%) contrast(101%);
}
.radio-format {
	display: flex;
	margin-top: 8px;
	gap: 80px;
}
.radio-format .form-check-label span.radio-text {
	padding-right: 0;
}
.item-not-found {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 50px 0;
	color: $light-gray;
}
.item-not-found.p-140 {
	padding: 140px 0;
}
.ps-items {
	max-height: 468px;
}
.ps-items.ps > .ps__rail-y, .search-map-result-lists.ps > .ps__rail-y {
	width: 15px;
}
.ps-items.ps > .ps__rail-y > .ps__thumb-y, .search-map-result-lists.ps > .ps__rail-y > .ps__thumb-y {
	width: 6px;
}
.list-items .item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 24px 8px 16px;
	gap: 20px;
	border-bottom: 1px solid $line-gray;
	cursor: pointer;
	@media (max-width: $screen-sm) {
		flex-direction: column;
		align-items: start;
	}
}
.list-items .item.booking {
	cursor: default;
}
.list-items .item:hover, .list-items .item.booking:nth-child(even), .list-items .item.booking:nth-child(even):hover {
	background-color: $background;
}
.list-items .item:last-child, .dropdown-menu > li:last-child, .rehab-detail .list-service:last-child, .sub-card .list-service:last-child, .list-checkbox .item-checkbox:last-child, .search-map-result-lists .place-list:last-child, .btn-icon:focus {
	border: none;
}
.list-items .item.centers > div:first-child {
	display: flex;
	align-items: center;
	gap: 8px;
	flex: 1 0 0;
}
.list-items .item.booking > div:first-child {
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex: 1 0 0;
}
.list-items .item.centers > div:nth-child(2), .list-items .item.centers > div:nth-child(3), .item.booking > div:nth-child(2), .item.booking > div:nth-child(3) {
	min-width: 200px;
}
.list-items .item.centers .logo, .list-items .item.booking .logo {
	width: 40px;
	height: 40px;
	background-color: $background;
	border-radius: 50%;
}
.list-items .item.centers .logo-icon, .list-items .item.booking .logo-icon {
	padding: 8px 10px 0;
}
.list-items .item.booking .guest {
	display: flex;
	align-items: center;
	gap: 8px;
}
.list-items .item.booking:hover {
	background: #FFF;
}
.list-items .item .icon, .list-checkbox .icon {
	border: none;
}
.dropdown-menu {
	border: none;
	border-radius: 8px;
	background: $background;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
}
.dropdown-menu:before {
	content: '';
	position: absolute;
	display: inline-block;
	top: -15px;
	right: 1rem;
	border-right: 0.75rem solid transparent;
	border-bottom: 1rem solid $background;
	border-left: 0.75rem solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.25);
}
.dropdown-menu:after {
	content: '';
	position: absolute;
	display: inline-block;
	top: -15px;
	right: 1rem;
	border-right: 0.75rem solid transparent;
	border-bottom: 1rem solid $background;
	border-left: 0.75rem solid transparent;
}
.dropdown-menu > li {
	display: flex;
	justify-content: center;
	align-items: center;
	// padding: 1rem;
	gap: 10px;
	border-bottom: 1px solid $line-gray;
}
.dropdown-menu > li:hover {
	background-color: $line-gray;
	cursor: pointer;
}
.address-map {
	width: 100%;
	margin-bottom: 8px;
	> div {
		border-radius: 10px;
	}
}
.rehab-detail > div:first-child {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 8px;
	border-bottom: 1px solid $line-gray;
}
.rehab-detail .center-name {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 16px;
	@media (max-width: $screen-sm) {
		flex-direction: column;
		align-items: start;
		padding: 0;
	}
}
.rehab-detail .center-name .logo {
	width: 60px;
	height: 60px;
	background-color: $background;
}
.rehab-detail .center-name > div:first-child {
	flex-direction: row;
	justify-content: flex-start;
	gap: 16px;
	flex: 1 0 0;
	@media (max-width: $screen-sm) {
		width: 100%;
	}
}
.rehab-detail .center-name > div:last-child {
	flex: none;
	margin-left: auto;
}
.rehab-detail .center-name .status {
	display: flex;
	align-items: center;
	font-size: 14px;
	gap: 8px;
}
.rehab-detail .nav-tabs .nav-link {
	display: flex;
	padding: 12px 16px;
	justify-content: center;
	align-items: center;
	background: transparent;
	border: none;
	color: #000;
	border-bottom: 4px solid transparent;
	cursor: pointer;
}
.rehab-detail .nav-tabs .nav-link.active {
	font-weight: bold;
	color: $primary;
	border-color: $primary;
}
.rehab-detail .card {
	border-radius: 8px;
	border: 1px solid $line-gray;
}
.rehab-detail .tab-content .card {
	gap: 0;
}
.rehab-detail .card .card-header, .sub-card .card-header, label.use-edit {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}
.rehab-detail .card .card-header .edit, .sub-card .card-header .edit, label.use-edit > span {
	font-size: 14px;
	line-height: 20px;
	color: $secondary;
	cursor: pointer;
}
.rehab-detail .tab-content .information > div:last-child, .card-column {
	display: flex;
	justify-content: center;
	gap: 20px;
}
.rehab-detail .tab-content .information > div:last-child .card, .card-column .card {
	flex: 1 0 0;
	margin: 0;
}
.rehab-detail .list-service, .sub-card .list-service {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 16px;
	border-bottom: 1px solid $line-gray;
	gap: 8px;
}
.sub-card .list-service.sm {
	padding: 12px 16px;
}
.rehab-detail .list-service.sequence, .sub-card .list-service.sequence {
	justify-content: flex-start;
	span {
		min-width: 30px;
	}
}
.rehab-detail .contact {
	display: flex;
	padding: 1rem 0;
	gap: 1rem;
	@media (max-width: $screen-sm) {
		flex-direction: column;
	}
}
.rehab-detail .contact > div {
	flex: 1 0 0;
}
.rehab-detail .contact > div:first-child {
	display: flex;
	align-items: center;
	gap: 16px;
}
.rehab-detail .contact > div:last-child {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.rehab-detail .contact > div:last-child > div {
	display: flex;
	gap: 8px;

	svg {
		color: $secondary;
	}
}
.rehab-detail .contact ul {
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0 0 0 8px;
	padding: 0;
	gap: 8px;
}
.rehab-detail .list-service.room > div:first-child, .rehab-detail .list-service.room > div:first-child > div {
	display: flex;
	align-items: center;
	gap: 8px;
	flex: 1 0 0;
}
.rehab-detail .list-service.room > div:first-child > div {
	padding: 0 8px;
}
.rehab-detail .google-map {
	height: 140px;
	width: 120px;
	> div {
		border-radius: 10px;
	}
}
.addr {
	display: flex;
	gap: 8px;
	flex: 1 0 0;
}
.rehab-detail .pic-add {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 20px;
	padding: 4px 0 20px;
}
.rehab-detail .pic-add button {
	position: relative;
}
.list-pictures {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}
.list-pictures .item-image {
	position: relative;
	width: 200px;
	height: 200px;

	img {
		border-radius: 8px;
	}
	span {
		position: absolute;
		background: rgba(255, 255, 255, 0.50);
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
		border-radius: 20px;
		top: 4px;
		right: 4px;
		padding: 4px;
		cursor: pointer;
	}
}
.dropdown-menu-end {
	transform: translate(15px, 40px) !important;
}
.list-pictures .dropdown-menu-end {
	transform: translate(12px, 48px) !important;
}
.list-pictures .item-image.small {
	width: 80px;
	height: 80px;
}
.list-pictures .item-image.small span.remove {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	top: -7px;
	right: -7px;
	background-color: $danger;
	color: #FFF;
	border-radius: 50%;
	padding: 0;
	cursor: pointer;
}
.bg-logo {
	position: absolute;
	height: 100vh;
}
.bg-logo img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}
.card-login {
	display: flex;
	justify-content: flex-end;
}
.card-login > div {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background: #FFF;
	box-shadow: -8px 0px 16px 0px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(10px);
	width: 799px;
	height: 100vh;
	padding: 60px 40px;
	border-radius: 40px 0px 0px 40px;
	@media (max-width: $screen-sm) {
		width: 100%;
		border-radius: unset;
	}
}
.card-login .language {
	display: flex;
	justify-content: flex-end;
	padding: 8px 0px;
	gap: 8px;
	width: 100%;
}
.card-login .contact {
	display: flex;
	color: $primary;
	padding: 8px 0px;
	gap: 8px;
	cursor: pointer;
}
.card-login form {
	display: flex;
	padding: 40px;
	flex-direction: column;
	align-items: center;
	gap: 40px;
	@media (max-width: $screen-sm) {
		gap: 20px;
		padding: 10px;
	}
}
.card-login .forgot-password {
	margin-bottom: 32px;
	text-align: right;
	> span {
		font-size: 14px;
		font-weight: bold;
		line-height: 20px;
		color: $secondary;
		cursor: pointer;
	}
}
.modal {
	--bs-modal-width: 600px;
}
.modal h6.title {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $line-gray;
	padding-bottom: 12px;
	margin-bottom: 1rem;
}
.modal h6.title.none {
	padding: 1rem;
	justify-content: flex-end;
}
.modal h6.title span {
	cursor: pointer;
	color: $primary;
}
.modal h6.title span:hover {
	color: $primary-600;
}
.modal .map {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 500px;
	gap: 1rem;
	margin-bottom: 1rem;
}
.modal .confirm-localtion {
	display: flex;
	justify-content: center;
	padding: 12px 1rem;
	button {
		min-width: 200px;
	}
}
.sub-card {
	border-radius: 8px;
	border: 1px solid $line-gray;
	margin: 0;
	gap: 0;
}
.sub-card .card-header {
	border-radius: 8px 8px 0 0;
}
.sub-card .card-header .edit {
	position: relative;
	display: flex;
	align-items: center;
	font-weight: normal;
	gap: 8px;
}
.sub-card .card-body {
	display: flex;
	flex-direction: column;
}
.sub-card .card-body .table {
	display: block;
	border-radius: 8px;
	border: 1px solid $line-gray;
	margin: 0;
}
.sub-card .card-body .table > thead td {
	font-size: 14px;
	background: $background;
	color: $light-gray;
	padding: 12px 1rem;
	line-height: 20px;
	border: none;
}
.sub-card .card-body .table > thead td:first-child {
	border-radius: 8px 0 0 0;
}
.sub-card .card-body .table > thead td:last-child {
	border-radius: 0 8px 0 0;
}
.sub-card .card-body .table > tbody td {
	padding: 12px 1rem;
}
.sub-card .card-body .table > tbody tr:last-child td, .sub-card .card-body .table.service-time tr:nth-last-child(-n + 2) td {
	border: none;
}
.sub-card .card-body .table > tbody tr:last-child td {
	border-radius: 8px;
}
.btn-center-save {
	display: flex;
	justify-content: center;
	padding: 40px 0 0;
	button {
		min-width: 200px;
	}
}
.add-number {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
}
.add-number svg {
	color: $primary;
	cursor: pointer;
}
.add-number input {
	text-align: center;
	border: none;
	width: 40px;
	padding: 0;
}
.list-checkbox .item-checkbox {
	display: flex;
	justify-content: space-between;
	padding: 8px 16px;
	border-bottom: 1px solid $line-gray;
}
.list-checkbox .item-checkbox.add {
	display: flex;
	padding: 16px;
	gap: 8px;
}
.form-check-input:disabled ~ .checkmark-radio, .form-check-input:disabled ~ .checkmark-checkbox {
	border-color: $primary-50;
}
.form-check-label input:checked:disabled ~ .checkmark-checkbox {
	background-color: $primary-50;
}
.room-details {
	flex-direction: row !important;
	justify-content: center;
	align-items: center;
	padding: 1rem 0;
}
.room-details > div {
	padding: 0px 16px;
	flex: 1 0 0;
}
.room-details > div .label {
	font-size: 14px;
	color: $light-gray;
	line-height: 20px;
}
.search-map-box {
	position: relative;
}
.search-map-input {
	border-radius: 8px;
}
.search-map-input input:focus {
	border-color: $line-gray;
}
.search-map-input .input-group-text {
	padding-right: 8px;
	color: $light-gray;
}
.search-map-result-lists {
	position: absolute;
	background-color: #FFFFFF;
	top: 50px;
	width: 100%;
	height: 300px;
	border-radius: 8px;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
	z-index: 1;
}
.place-list {
	padding: 8px 1rem;
	gap: 20px;
	border-bottom: 1px solid $line-gray;
	cursor: pointer;
}
.place-list:hover {
	background: $background;
}
.place-list > div:first-child {
	display: flex;
	align-items: center;
	gap: 8px;
}
.place-list > div:last-child {
	font-size: 12px;
	color: $light-gray;
	line-height: 20px;
}
.place-list img {
	width: 16px;
}
.columns {
	display: flex;
	gap: 20px;
}
.columns > div {
	flex: 1 0 0;
}
.sub-header {
	font-size: 14px;
	font-weight: normal;
	color: $light-gray;
	line-height: 20px;
}
.title-description {
	font-size: 14px;
	color: $light-gray;
	line-height: 20px;
}
.rehab-total {
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 60px;
	color: $secondary;
	line-height: 150%;
	flex: 1 0 0;
}
.bar-bullets {
	display: flex;
	justify-content: center;
	gap: 35px;
}
.bar-bullets > div {
	display: flex;
	align-items: center;
	gap: 8px;
}
.bar-bullets span {
	display: block;
	width: 16px;
	height: 16px;
	border-radius: 50%;
}
.bar-bullets span.text-4C0563 {
	background-color: #4C0563;
}
.bar-bullets span.secondary {
	background-color: $secondary;
}
.bar-bullets span.primary {
	background-color: $primary;
}
.search-rehab {
	display: flex;
	gap: 16px;
}
.search-rehab svg {
	color: $light-gray;
}
.nursing-status {
	font-weight: normal;
	font-size: 12px;
	padding: 4px 8px;
	border-radius: 20px;
	line-height: 16px;
	min-width: 100px;
}
.change-status {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	gap: 16px;
}
.change-status .text-status {
	font-weight: normal;
}
.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb, .react-toggle--focus .react-toggle-thumb {
	box-shadow: none;
}
.modal-action {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 16px;
	gap: 10px;
}
.system-usage > div {
	display: flex;
	justify-content: space-between;
}
.btn.view-visit {
	position: relative;
}
.btn.view-visit .badge {
	position: absolute;
	top: -8px;
	right: -8px;
}
.list-visits {
	display: flex;
	flex-direction: column;
	min-height: 500px;
}
.list-visits > div {
	display: flex;
	border-bottom: 1px solid $line-gray;
	padding: 8px 16px;
	flex-direction: column;
	gap: 8px;
}
.list-visits > div > div:last-child {
	display: flex;
	justify-content: flex-end;
}
.list-visits .contact-success {
	font-weight: normal;
	font-size: 16px;
	justify-content: center;
	line-height: 24px;
	padding: 4px 8px;
	gap: 10px;
	border-radius: 16px;
}
.list-visits .contact-detail span {
	color: $light-gray;
	padding-right: 12px;
}

.img-square {
  border-radius: 10px;
  .icon-plus {
    right: -3px;
    bottom: -3px;
  }
}