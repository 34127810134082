@import "../fonts/noto-sans/font.scss";

::placeholder, .css-1jqq78o-placeholder {
  color: $light-gray !important;
}
:focus-visible {
  outline: none;
}
html {
  touch-action: pan-x pan-y;
}
body, .react-tel-input {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: 16px;
  line-height: 24px;
  background-color: $background;
  color: #000;
}
.hide {
  display: none;
}
._max-width-deduct-1rem {
  max-width: calc(100% - 1rem);
}
._flex-center {
  align-items: center;
  justify-content: center;
}
._float-right {
  float: right;
}
._margin-auto {
  margin: auto !important;
}
._unmargin {
  margin: 0 !important;
}
._unmargin-top {
  margin-top: 0 !important;
}
._unmargin-bottom {
  margin-bottom: 0 !important;
}
._margin-top-16 {
  margin-top: 16px;
}
._margin-right-20 {
  margin-right: 20px;
}
._unpadding {
  padding: 0 !important;
}
._unborder-radius-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
._unborder-radius-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.background-success {
  background-color: $success;
}
.background-warning {
  background-color: $warning;
}
.background-danger {
  background-color: $danger;
}
.underline {
  color: $line-gray;
  opacity: 1;
}
body.modal-open {
  overflow: hidden;
}
.react-tel-input {
  background-color: transparent;
}
a, .nav-link, .btn-link {
  color: $primary-900;
  text-decoration: none;
}
.text-inline {
  display: flex;
  gap: 8px;
  align-items: center;
  >._flex-right {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }
}
.text-inline.column {
  flex-direction: column;
  gap: 4px;
  align-items: start;
}
.text-right {
  text-align: right;
}
.text-link a {
  // color: $light-blue;
  text-decoration: underline;
}
.text-link a:hover {
  color: $secondary-100;
}
.btn-link-secondary {
  color: $secondary-300;
  text-decoration: none;
}
a:hover, .nav-link:hover, a:focus, .nav-link:focus, .btn-link:hover {
  color: $secondary-200;
}
.btn-link-secondary:hover {
  color: $primary-100;
}
.btn.disabled {
  border-color: $dark-gray;
}
.h1, h1 {
  line-height: 42px;
  font-weight: 700;
}
.h2, h2 {
  line-height: 36px;
  font-weight: 600;
}
.h3, h3 {
  line-height: 30px;
  font-weight: 600;
}
.none-resize {
  resize: none;
}
.text-underline {
  text-decoration: underline;
}
.text-pbb {
  font-family: 'Noto Sans Thai', sans-serif;
  font-style: bold;
  font-size: $size-16 !important;
  font-weight: 600 !important;
  line-height: 24px;
}
.text-pb {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: $size-16 !important;
  font-weight: 300 !important;
  line-height: 24px;
}
.text-sbb {
  font-family: 'Noto Sans Thai', sans-serif;
  font-style: bold;
  font-size: $size-12 !important;
  line-height: 16px;
  font-weight: 600;
}
.text-sb {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: $size-12 !important;
  line-height: 16px;
  font-weight: 300;
}
.text-primary {
  color: $primary !important;
}
.text-primary-300 {
  color: $primary-300;
}
.text-primary-900 {
  color: $primary-900 !important;
}
.text-secondary {
  color: $secondary !important;
}
.text-secondary-200 {
  color: $secondary-200 !important;
}
.text-success {
  color: $success !important;
}
.text-warning {
  color: $warning !important;
}
.text-danger {
  color: $danger !important;
}
.text-light {
  color: $light-gray !important;
}
.text-light-gray {
  color: $light-gray !important;
}
.text-dark-gray {
  color: $dark-gray !important;
}
.text-line-gray {
  color: $line-gray;
}
.text-white {
  color: #FFF;
}
.text-semibold {
  font-weight: 500;
}
.lh-15-6 {
  line-height: 15.6px;
}
.bg-success {
  background-color: $success !important;
}
.bg-primary {
  background-color: $primary !important;
}
.bg-secondary {
  background-color: $secondary !important;
}
.bg-surface {
  background-color: $surface;
}
.bg-dark-gray {
  background-color: $dark-gray;
}
.bg-disable {
  background-color: $light-gray;
}
.border-secondary-200 {
  border-color: $secondary-200;
}
.badge.bg-surface {
  color: $primary-900;
}
.w-200px {
  width: 200px;
}
.w-300px {
  width: 300px;
}
.min-width-60 {
  min-width: 60px;
}
.min-width-70 {
  min-width: 70px;
}
.min-width-80 {
  min-width: 80px;
}
.min-width-100 {
  min-width: 100px;
}
.min-width-120 {
  min-width: 120px;
}
.min-width-140 {
  min-width: 140px;
}
.min-width-160 {
  min-width: 160px;
}
.min-width-180 {
  min-width: 180px;
}
.min-width-200 {
  min-width: 200px;
}
.min-width-230 {
  min-width: 230px !important;
}
.max-width-100 {
  max-width: 100px;
}
.max-width-120 {
  max-width: 120px;
}
.max-width-140 {
  max-width: 140px;
}
.max-width-160 {
  max-width: 160px;
}
.max-width-180 {
  max-width: 180px;
}
.max-width-200 {
  max-width: 200px;
}
.max-width-230 {
  max-width: 230px !important;
}
.max-width-240 {
  max-width: 240px !important;
}
.max-width-250 {
  max-width: 250px !important;
}
.max-width-260 {
  max-width: 260px !important;
}
.min-width-280 {
  min-width: 280px !important;
}
.min-width-300 {
  min-width: 300px !important;
}
.min-width-350 {
  min-width: 350px;
}
.min-width-400 {
  min-width: 400px !important;
}
.max-width-260 {
  max-width: 260px;
}
.max-width-350 {
  max-width: 350px;
}
.max-width-400 {
  max-width: 400px;
}
.max-width-450 {
  max-width: 450px;
}
.max-width-500 {
  max-width: 500px;
}
.max-width-600 {
  max-width: 600px;
}
.max-width-820 {
  max-width: 820px;
}
.min-height-500 {
  min-height: 500px !important;
}
.min-height-unset {
  min-height: unset !important;
}
.max-height-180 {
  max-height: 180px;
}
.max-height-200 {
  max-height: 200px;
}
.max-height-250 {
  max-height: 250px;
}
.cursor-pointer {
  cursor: pointer;
}
.lh-15 {
  line-height: 15px;
}
.lh-19 {
  line-height: 19px;
}
.lh-30 {
  line-height: 30px;
}
.mb-00 {
  margin-bottom: 0 !important;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-120 {
  margin-bottom: 120px !important;
}
.me-120 {
  margin-right: 120px !important;
}
.p-20 {
  padding: 20px;
}
.square-60 {
  height: 60px !important;
  width: 60px !important;
}
.square-80 {
  height: 80px !important;
  width: 80px !important;
}
.circle-primary {
  height: 24px;
  width: 24px;
  margin-right: 0.125rem;
  border: 3px solid $primary;
  border-radius: 30px;
}
.form-control, .form-select {
  padding: 7px 1rem;
  border-radius: 8px;
  border-color: $line-gray;
  line-height: 24px;
}
.form-control:focus, .form-select:focus, .form-data .form-control {
  border-color: $light-gray;
}
textarea.form-control {
  border-radius: 8px;
}
.form-control:disabled {
  background-color: $line-gray;
}
.input-group {
  .form-control:not(:last-child) {
    border-right: unset;
  }
  :disabled ~ .input-group-text {
    background-color: $line-gray;
  }
}
.slide-range {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #EBEBEB;
  border-radius: 10px;
  background-image: linear-gradient($secondary-200, $secondary-200);
  background-size: 1% 100%;
  background-repeat: no-repeat;
}
.slide-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: $secondary-100;
  cursor: pointer;
  transition: background .3s ease-in-out;
}
.slide-range::-webkit-slider-thumb:hover {
  background: $secondary-100;
}
.slide-range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.symptoms-button {
  display: block;
  background-color: $secondary-200;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: #fff;
  font-size: 30px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
}
.modal-open .navbar {
  padding-right: 15px;
}
.label-title {
  display: inline;
  font-size: 20px;
  font-weight: 600;
  color: $secondary;
  margin-left: 8px;
  margin-right: 16px;
}
.label-profile {
  display: inline;
  font-size: 20px;
  font-weight: 600;
}
.title-header {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  @media (max-width: $screen-sm) {
    gap: 16px;
  }
  .btn-icon {
    cursor: pointer;
    padding: 0;
  }
  .label-title {
    margin: 0 8px 0 0;
    font-size: $size-24;
    font-weight: 600;
  }
  .label-profile {
    font-size: $size-24;
    font-weight: 600;
    @media (max-width: $screen-sm) {
      font-size: $size-20;
    }
  }
  .content-right {
    display: flex;
    justify-content: right;
    flex: 1;
  }
}
.text-group-inline {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}
.input-search {
  .form-control {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-color: $line-gray;
  }
  .input-group-text {
    color: $light-gray;
    border-color: $line-gray;
  }
  .input-group .input-group-text {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-color: $line-gray;
  }
}
.navbar {
  @media (max-width: $screen-lg) {
    width: 100%;
    padding: 0;
  }
  .container-fluid {
    height: 44px;
  }
  .label-title {
    display: inline;
    font-size: 28px;
    font-weight: 600;
    color: $secondary;
    margin-left: 8px;
    margin-right: 16px;
  }
  .label-profile {
    display: inline;
    font-size: 28px;
    font-weight: 600;
  }
  .input-search {
    width: 400px;
    height: 44px;
    .form-control {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-color: $line-gray;
    }
    .input-group-text {
      color: $light-gray;
      border-color: $line-gray;
    }
    .input-group .input-group-text {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-color: $line-gray;
    }
  }
  .nav-right {
    margin-right: 36px;
    display: flex;
    gap: 24px;
    .icon {
      border-radius: 50%;
      --bs-bg-opacity: 1;
      background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
      width: 44px;
      height: 44px !important;
      .btn {
        padding: 5px;
      }
      .dropdown.show {
        > .btn-dropdown, > .btn.svg {
          border-radius: 50%;
          background-color: $secondary;
          color: #FFF;
        }
        .btn-dropdown {
          border-radius: 50%;
          background-color: $secondary;
        }
        .dropdown-menu {
          inset: 8px 0px auto auto !important;
          width: 387px;
          .notifications-items {
            min-height: 1rem;
            max-height: calc(80vh - 100px);
            @media (max-width: $screen-md) {
              max-height: calc(90vh - 50px);
            }
            overflow: auto;
            .block-icon {
              width: 52px;
              height: 52px;
              background-color: $surface;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .dropdown-item > .text-inline {
              white-space: normal;
              word-break: break-word;
              gap: 16px;
            }
            .notification-content {
              display: flex;
              flex-direction: column;
              gap: 4px;
              max-width: 284px;
              .time {
                color: $secondary-300;
              }
            }
          }

          .notifications-items::-webkit-scrollbar {
            width: 3px;
          }

          .notifications-items::-webkit-scrollbar-thumb {
            background: #5466b3;
            border-radius: 3px;
          }

          .notifications-items::-webkit-scrollbar-thumb:hover {
            background: #3a4c99;
          }
        }
      }
      .menu.dropdown {
        .btn-dropdown {
          border-radius: 50%;
          padding: 4px;
        }
        .btn-dropdown:hover {
          background-color: $secondary;
          border-radius: 50%;
          color: #FFF;
        }
      }
    }
    .icon .btn:hover {
      background-color: $secondary;
      border-radius: 50%;
      color: #FFF;
    }
    @media (max-width: $screen-lg) {
      margin-top: 0;
      margin-right: 0;
      .icon {
        background-color: $surface !important;
        width: 36px;
        height: 36px !important;
        .menu.dropdown {
          .btn-dropdown {
            width: 36px;
            height: 36px !important;
          }
        }
      }
      .icon .btn:hover {
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
      }
      .icon-end {
        margin-right: 0;
      }
    }
    @media (max-width: $screen-md) {
      gap: 16px;
    }
    @media (max-width: $screen-sm) {
      gap: 8px;
      .icon {
        width: 32px;
        height: 32px !important;
        .menu.dropdown {
          .btn-dropdown {
            width: 32px;
            height: 32px !important;
            padding: 1px;
          }
        }
        .btn {
          padding: 2px;
        }
      }
    }
    @media (max-width: $screen-xs) {
      .icon {
        width: 28px;
        height: 28px !important;
        .menu.dropdown {
          .btn-dropdown {
            width: 28px;
            height: 28px !important;
          }
        }
        .btn {
          padding: 2px;
        }
      }
    }
  }
}
.navbar-toggler {
  padding: 0 8px;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar-toggler svg {
  color: $primary-900;
}
.navbar .navbar-brand, .sidebar-header .navbar-brand {
  padding: 0;
  margin: auto;
}
.navbar .logo {
  height: 59px;
  padding: 5px 0;
}
.sidebar-header {
  .logo {
    width: 100%;
    height: 40px;
    margin-top: 0;
  }
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 1rem;
}
.navbar-button .nav-item {
  margin-left: 20px;
}
.navbar-button .nav-item .btn {
  border-radius: 30px;
  min-width: 100px;
}
.navbar-button .nav-item .btn.icon {
  background-color: #fff;
  border: solid 1px #fff;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  min-width: auto;
  padding: 0;
}
.navbar-button .nav-item .btn.icon:hover, .navbar-button .nav-item.show .btn.icon {
  background-color: $secondary-200;
  color: #fff;
}
.navbar-button .nav-item .btn.icon.account {
  display: inline-flex;
  align-items: center;
  background-color: $surface;
  border-color: $surface;
  border-radius: 22px;
  width: auto;
  padding: 5px 8px 5px 5px;
}
.navbar-button .nav-item .btn.icon.account:hover, .navbar-button .nav-item.show .btn.icon.account {
  background-color: $secondary-200;
}
.navbar-button .nav-item.show .btn.icon.account svg {
  color: $primary-900;
}
.navbar-button .nav-item .btn.icon.account .account-icon, .navbar-button .dropdown-menu .card-account .account-icon, .doctor-img, .block-img-profile {
  display: inline-block;
  background-color: $primary-50;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  padding: 3px;
  text-align: center;
}
.navbar-button .dropdown-menu .card-account .account-icon, .doctor-img {
  color: $primary-900;
  height: 64px;
  width: 64px;
  padding: 18px;
}
.doctor-img, .block-img-profile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  width: 72px;
  padding: 0;
}
.doctor-img img {
  object-fit: cover;
  height: 72px;
}
.block-img-profile img {
  object-fit: cover;
}
.doctor-img .status {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: $success;
}
.doctor-img .status.busy {
  background-color: $warning;
}
.doctor-img.avatar-60 {
  height: 60px;
  width: 60px;
}
.doctor-img.avatar-48 {
  height: 48px;
  width: 48px;
}
.doctor-img.avatar-40 {
  height: 40px;
  width: 40px;
}
.dropdown-item.active, .dropdown-item:active {
  color: #1e2125;
  background-color: #e9ecef;
}
.navbar-button .nav-item .btn.icon.account .account-icon img, .navbar-button .dropdown-menu .card-account .account-icon img, .doctor-img img, .block-img-profile img {
  border-radius: 50%;
  height: inherit;
  width: inherit;
}
.navbar-button .nav-item .btn.icon.account:hover .account-icon, .block-img-profile {
  color: $primary-900;
}
.navbar-button .nav-item.dropdown .dropdown-menu, .consult .timezone .dropdown-menu {
  border: 1px solid $line-gray;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  top: 16px !important;
  padding: 16px;
  overflow-y: auto;
  width: 400px;
  max-height: 600px;
}
.navbar-button .nav-item.dropdown .dropdown-menu::-webkit-scrollbar, .consult .timezone .dropdown-menu::-webkit-scrollbar {
  width: 0;
}
.navbar-button .dropdown-menu > :nth-child(2), .navbar-button .dropdown-menu .dropdown-item.first-child {
  border-radius: 10px 10px 0 0;
}
.navbar-button .dropdown-menu > :last-child, .navbar-button .dropdown-menu .dropdown-item.last-child {
  border-radius: 0 0 10px 10px;
}
.navbar-button .dropdown-menu .dropdown-item.round, .sidebar .side-menu .nav-item .dropdown-item.round {
  border-radius: 10px;
}
.navbar-button .dropdown-menu > :last-child .row {
  border: none;
}
.navbar-button .dropdown-header {
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: $secondary-200;
  margin-bottom: 16px;
  padding: 0;
}
.navbar-button .dropdown-item, .sidebar .side-menu .nav-item .dropdown-item {
  background: $surface;
  color: $primary-900;
  padding: 0 13px;
}
.navbar-button .dropdown-item .row {
  padding: 16px 0;
  border-bottom: solid 1px #fff;
}
.navbar-button .dropdown-item .row .col, .navbar-button .dropdown-item .row .col-auto {
  padding: 0;
}
.navbar-button .dropdown-item .menu-icon {
  width: 35px;
}
.navbar-button .dropdown-item:hover, .sidebar .side-menu .nav-item .dropdown-item:hover {
  background-color: $primary-50;
}
.navbar-button .dropdown-item:hover .row {
  border-color: $primary-50;
}
.navbar-button .dropdown-menu .card-account {
  background-color: $surface;
  padding: 16px;
  border-radius: 10px;
}
.menu.dropdown {
  cursor: pointer;
  .dropdown-menu {
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));
    border: none;
    padding: 0;
    border-radius: 10px;
    .dropdown-item {
      padding: 16px 16px;
      color: $primary;
    }
    .dropdown-item:first-child:hover {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .dropdown-item:last-child:hover {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
.block-register-doctor {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-100;
  border-radius: 10px;
  min-height: 120px;
  margin-bottom: 20px;
  padding: 20px;
  .icon-join-us {
    position: relative;
    left: 0;
    height: 95px;
    bottom: -12px;
  }
  .logo-icon-white {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 209px;
  }
  .detail {
    text-align: center;
    > h6 {
      margin-bottom: 0.25rem;
    }
    > div {
      font-size: 12px;
    }
  }
  .block-detail {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 20px;
    padding: 36px 0;
    max-width: 825px;
    width: 825px;
    .row {
      margin: 0;
    }
    @media (max-width: $screen-lg) {
      margin-right: 16px;
      padding: 12px 0 8px;
      max-width: 615px;
    }
    @media (max-width: $screen-md) {
      max-width: 350px;
      padding: 8px 0;
    }
    @media (max-width: $screen-sm) {
      max-width: 320px;
      padding: 8px 0;
    }
    @media (max-width: $screen-xs) {
      margin-right: 0;
    }
    .footer {
      margin-top: 8px;
      padding: 0;
      .btn {
        height: 36px;
        width: 200px;
      }
      @media (max-width: $screen-lg) {
        margin-top: 16px;
      }
      @media (max-width: $screen-md) {
        margin-top: 8px;
      }
      @media (max-width: $screen-sm) {
        margin-top: 8px;
      }
    }
  }
  @media (max-width: $screen-sm) {
    .logo-icon-white {
      top: 0;
      height: 120px;
      width: 132px;
    }
    .icon-join-us {
      height: 60px;
    }
  }
}
.block-register-success {
  .block-banner {
    position: relative;
    width: 100%;
    margin-top: 16px;
    border-radius: 10px;
    background-color: #FFF;
    padding: 32px 16px;
    min-height: unset;
    .block-icon {
      padding: 3px 0;
      text-align: center;
    }
  }
  .block-button {
    margin-top: 40px;
  }
}
.modal-doctor-register {
  .modal-body {
    padding: 34px;
    @media (max-width: $screen-sm) {
      padding: 26px 1rem;
    }
  }
  .title {
    display: block;
    font-size: $size-24;
    text-align: center;
  }
  .logo-join-us {
    margin-bottom: 1rem;
  }
  .icon-join-us {
    width: 286px;
  }
  .row.detail {
    .col {
      color: #000;
    }
  }
}
.modal-doctor-register, .step-register-doctor {
  .block-kyc-process {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000;
    .kyc-list {
      margin-top: 16px;
      width: 280px;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 13px;
      .col-index {
        flex: none;
        order: 0;
        flex-grow: 0;
        .index {
          background-color: $secondary;
          border-radius: 20px;
          height: 28px;
          width: 28px;
          text-align: center;
          font-size: $size-16;
          font-weight: 600;
          color: #FFF;
          padding-top: 2px;
        }
      }
      .col-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        flex: none;
        order: 1;
        flex-grow: 0;
        .item-detail {
          font-size: $size-16;
          font-weight: 300;
          line-height: 24px;
          color: #000;
        }
        .item-option {
          padding-left: 16px;
          margin-bottom: 0;
          font-size: $size-12;
          font-weight: 300;
          line-height: 16px;
          color: #000;
        }
      }
    }
  }
  .footer {
    margin-top: 1.5rem;
  }
}
.modal-doctor-ekyc {
  .modal-body {
    padding: 0;
    .iframe-ekyc {
      width: 100%;
      height: 90vh;
    }
  }
}
.modal-doctor-career, .modal-doctor-schedule, .custom-modal {
  max-width: 600px;
  margin: auto;
  @media (max-width: $screen-sm) {
    max-width: 400px;
  }
  .modal-header {
    border: unset;
    padding: 8px;
  }
  .modal-title {
    width: 100%;
    font-weight: 500;
    font-size: $size-20;
  }
  .label {
    text-align: center;
    font-weight: 300;
    font-size: $size-16;
    margin: auto;
  }
  .label.request-not-response {
    margin: 35px 0;
  }
  .modal-body {
    padding-top: 8px;
  }
}
.custom-modal {
  line-height: 24px;
  .modal-header {
    padding: 16px 16px 0;
    .modal-title {
      font-family: 'Noto Sans Thai', sans-serif;
      font-size: $size-16 !important;
      font-weight: 600 !important;
      line-height: 24px;
    }
  }
  .modal-header.primary-50 {
    background-color: $primary-50;
    padding: 15px;
  }
  .modal-body {
    .large-img {
      width: -webkit-fill-available;
    }
  }
}
.modal-change-booking {
  .modal-body {
    max-height: 700px;
    overflow: auto;
    .patient-info {
      margin: 8px auto 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      .block-patient {
        .block-detail-profile {
          .detail {
            .detail-item {
              .code {
                font-size: 12px;
                color: $light-gray;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
    .nav-tabs {
      border-radius: 10px;
      background-color: $surface;
      .nav-item {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .nav-link {
          border: unset !important;
          // border-radius: 10px;
          color: $light-gray;
          padding: 16px 0;
          background-color: $surface;
        }
        .nav-link.active {
          border-radius: 10px;
          color: $primary;
        }
        .nav-link.active:hover {
          color: $primary-500;
        }
      }
      .nav-item:hover, .nav-item:active {
        border-radius: 10px;
        .nav-link {
          color: $primary;
        }
        .nav-link:hover {
          background-color: $surface;
        }
        background-color: $surface;
      }
      .nav-item.active::after {
        content: "";
        border-bottom: 2px solid $primary;
        margin: auto;
        width: 120px;
        margin-top: 1px;
        margin-bottom: -1px;
      }
      hr {
        border-top: unset;
        border-left: 2px solid #FFF;
        height: 40px;
        margin: auto;
        opacity: 1;
      }
    }
    .tab-content {
      .tab-pane.active {
        display: flex;
        flex-direction: column;
      }
      .tab-pane {
        gap: 20px;
        .drug-selected-total {
          background-color: $danger;
          padding: 2px 10px;
          border-radius: 20px;
        }
        .custom-input-number {
          .custom-number {
            font-size: $size-16;
            font-weight: 300;
            line-height: 24px;
          }
        }
        .date-display {
          .form-control, .input-group-text {
            cursor: pointer;
            background-color: #FFF;
          }
        }
      }
    }
  }
}
.modal-doctor-schedule {
  max-width: 600px;
  @media (max-width: $screen-sm) {
    max-width: 500px;
  }
  .modal-title {
    font-weight: 300;
    font-size: $size-16;
  }
  .label {
    font-weight: 600;
    font-size: $size-16;
    padding-top: 6px;
  }
  .btn-remove {
    color: $secondary;
  }
  .btn-remove:hover, .btn-remove:focus, .btn-remove:active {
    color: $light-gray;
  }
}
.modal-calling {
  max-width: 820px;
  .modal-header {
    margin: 28px 40px 0;
    padding: 0;
    .modal-title {
      text-align: left;
    }
  }
  .modal-body {
    margin: 40px;
    padding: 0;
    .block-patient {
      margin: auto;
      gap: 16px;
      .block-img-profile {
        width: 100px;
        height: 100px;
        @media (max-width: $screen-sm) {
          width: 80px;
          height: auto;
          .icon-profile {
            width: 32px;
          }
        }
      }
      .block-img-profile.image {
        height: 100px;
        @media (max-width: $screen-sm) {
          height: 80px;
        }
      }
      .block-detail-profile {
        display: flex;
        font-size: $size-20;
        margin: auto 0;
        flex-wrap: wrap;
        gap: 16px;
        .title {
          font-size: $size-32;
        }
      }
    }
  }
}
.custom-input-number {
  border-radius: 10px;
  background-color: $bg-surface;
  color: $secondary;
  font-weight: 700;
  .custom-number {
    font-size: $size-28;
    text-align: center;
    border: 1px solid $surface;
    border-radius: 10px;
    color: $secondary;
    font-weight: 700;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    padding: 0;
  }
  input[type="number"] {
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .custom-number:first-child {
    border-right: unset;
  }
  >svg {
    margin: auto;
  }
  .block-spin-button.disabled {
    background-color: $line-gray;
  }
  .block-spin-button {
    display: flex;
    flex-direction: column;
    padding: 0 2px !important;
    background-color: $bg-light;
    border: 1px solid $surface;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    align-items: center;
    justify-content: center;
    .btn-spin {
      border: unset;
      padding: 0;
    }
  }
}
.health-record {
  .nav-tabs {
    border-radius: 10px;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    background-color: $bg-surface;
    margin: auto;
    .nav-item {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .nav-link {
        border: unset !important;
        color: $light-gray;
        padding: 16px 0;
        background-color: $bg-surface;
      }
      .nav-link.active {
        border-radius: 10px;
        color: $primary;
      }
      .nav-link.active:hover {
        color: $primary-500;
      }
    }
    .nav-item:hover, .nav-item:active {
      border-radius: 10px;
      .nav-link {
        color: $primary;
      }
      .nav-link:hover {
        background-color: $bg-surface;
      }
      background-color: $bg-surface;
    }
    .nav-item.active::after {
      content: "";
      border-bottom: 2px solid $primary;
      margin: auto;
      width: 120px;
      margin-top: 1px;
      margin-bottom: -1px;
    }
    hr {
      border-top: unset;
      border-left: 2px solid $color-white;
      height: 40px;
      margin: auto;
      opacity: 1;
    }
  }
  .tab-content {
    .tab-pane.active {
      display: flex;
      flex-direction: column;
    }
    .tab-pane {
      gap: 20px;
      .drug-selected-total {
        background-color: $danger;
        padding: 2px 10px;
        border-radius: 20px;
      }
      .custom-input-number {
        .custom-number {
          font-size: $size-16;
          font-weight: 300;
          line-height: 24px;
        }
      }
      .date-display {
        .form-control, .input-group-text {
          cursor: pointer;
          background-color: $bg-light;
        }
      }
      .record {
        .card-body {
          background-color: $bg-surface;
          border-radius: 10px;
        }
        .dropdown {
          .dropdown-toggle {
            border: unset;
            border-radius: 16px;
            padding: 8px;
            background-color: $color-white;
            .label {
              margin-right: 2rem;
            }
          }
        }
        .dropdown-toggle::after {
          top: 18px;
          background-image: url(../images/arrow-down-blue.svg);
        }
      }
      .latest {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .block-data {
          border: 1px solid $primary-300;
          border-radius: 10px;
          padding: 8px;
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;
          :last-child {
            margin-top: 4px;
          }
        }
      }
    }
    .tab-pane.document, .tab-pane.health, .tab-pane.latest {
      margin: auto;
      .row {
        align-items: center;
        margin: 0;
        padding: 1rem 0;
        border-bottom: 1px solid $line-gray;
      }
      .empty {
        margin: 100px auto 40px !important;
        margin: auto;
        color: $light-gray;
      }
    }
  }
}
.health-record.custom {
  .nav-tabs {
    background-color: unset;
    .nav-item {
      a {
        padding: 10px 0;
        color: $light-gray;
      }
    }
    .nav-item.active {
      a {
        color: $primary-300;
      }
    }
    .nav-item.active::after {
      width: 90%;
    }
  }
  .tab-content {
    .tab-pane.details {
      > * {
        padding: 10px 0;
        border-bottom: 1px solid $primary;
      }
    }
  }
}
.bmi div {
  padding: 10px;
}
.bmi div:first-child {
  background-color: #ffc43f;
  border-radius: 10px 0 0 10px;
}
.bmi div:nth-child(2) {
  background-color: #9acd32;
}
.bmi div:last-child {
  background-color: #ff7556;
  border-radius: 0 10px 10px 0;
}
.bmi div.active::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #f6f7f9;
  transform: rotate(45deg);
  top: 14px;
  right: 0;
  left: 0;
  margin: auto;
}
.consult-detail {
  .card.block-diagnosis {
    .card-body {
      .block-data {
        padding: 0;
        .header {
          padding: 8px;
          background-color: $primary-50;
        }
      }
    }
  }
}
.company-detail {
  .block-img-profile {
    width: 160px;
    height: 160px;
  }
  .custom-number {
    font-size: $size-18;
  }
}
.video {
  position: absolute;
  background-color: #000;
  color: $surface;
  height: 100%;
  width: 100%;
  overflow: hidden;
  .block-main {
    width: 100%;
    height: 100%;
    padding: 32px 40px 0;
    display: flex;
    gap: 20px;
    @media (max-width: $screen-lg) {
      padding: 0;
    }
    .block-item {
      flex: 1;
    }
    .block-video {
      width: 100%;
      height: calc(100% - 100px);
      iframe {
        border-radius: 20px;
      }
      @media (max-width: $screen-lg) {
        height: calc(100% - 160px);
      }
      @media (max-width: $screen-sm) {
        height: calc(100% - 170px);
      }
    }
    .block-waiting-period {
      margin: 10% auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      text-align: center;
      @media (max-width: $screen-lg) {
        padding: 0 20px;
      }
      @media (max-width: $screen-md) {
        padding: 0 16px;
      }
      .patient-img {
        margin: 0 auto;
        border-radius: 60px;
        width: 120px;
        height: 120px;
        background: rgba(255, 255, 255, 0.2);
        img {
          height: 120px;
          object-fit: cover;
          width: 100%;
          border-radius: 60px;
        }
        .icon-profile {
          margin-top: 15px;
        }
      }
      .block-call-end {
        width: 100%;
        max-width: 590px;
        margin: 0 auto;
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 25px;
        .text-label {
          margin-top: 16px;
          font-size: $size-20;
          font-weight: 500;
        }
      }
      .block-call-action {
        margin: 0 auto;
        display: flex;
        gap: 65px;
        max-width: fit-content;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: $screen-lg) {
          gap: 65px;
        }
        @media (max-width: $screen-md) {
          gap: 30px;
        }
        @media (max-width: $screen-sm) {
          gap: 20px;
        }
        @media (max-width: $screen-xs) {
          gap: 10px;
        }
        .block-icon {
          .label {
            width: 100px;
          }
          .icon {
            margin: 0 auto;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            padding-top: 20px;
            background-color: #FFF;
            margin-bottom: 8px;
            @media (max-width: $screen-xs) {
              padding-top: 10px;
              width: 60px;
              height: 60px;
            }
          }
          .icon.active {
            background-color: $secondary;
            svg {
              color: #FFF !important;
            }
          }
          .icon:hover {
            opacity: 0.8;
          }
          .icon.call-again {
            background-color: $success;
          }
          .icon.change-booking {
            background: rgba(255, 255, 255, 0.2);
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
    .block-info {
      background: #292929;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      z-index: 1;
    }
    .block-menu-bar {
      padding: 0 16px;
      background: #292929;
      border-radius: 10px;
      flex: 1;
      .block-consult-description.active {
        .btn-link {
          color: $secondary;
        }
        .underline {
          color: $secondary;
        }
      }
      .block-consult-description {
        @media (max-width: $screen-lg) {
          padding: 0;
        }
        .btn-link {
          font-size: $size-12;
          color: $light-gray;
          padding: 20px 0;
          @media (max-width: $screen-lg) {
            padding: 14px 0;
          }
        }
        .btn-light {
          color: $primary;
          font-size: $size-10;
          font-weight: 600;
          padding: 8px 20px;
          border: 5px solid $primary;
          margin: 4px 0;
        }
        .btn-chevron {
          margin: 8px 0;
          color: #FFF;
          rotate: 270deg;
        }
        .underline {
          margin: auto;
          width: 75%;
          color: #292929;
        }
      }
    }
    .block-detail.chat {
      height: 100%;
      max-height: unset;
      overflow: unset;
      .block-message {
        height: 100%;
        .message-data {
          height: 100%;
          .list-chat {
            height: calc(100% - 140px);
          }
          .block-chat-sent {
            color: $primary;
          }
        }
      }
    }
    .block-detail {
      margin: 0 16px 16px;
      max-height: calc(100% - 120px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .card.drug-allergy {
        background-color: $secondary-50;
      }
      .card {
        margin-bottom: 0;
        border: 1px solid $line-gray;
        border-radius: 10px;
        background-color: #FFF;
        .card-header {
          height: 60px;
          background-color: $primary-50;
          color: $primary;
          padding: 16px 16px 14px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          font-size: $size-20;
          font-weight: 500;
        }
        .card-body {
          .search-input {
            svg {
              color: $light-gray;
            }
            .input-group {
              .input-group-text, .form-control {
                border-color: $surface;
                background-color: $surface;
              }
              :first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              }
              :last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }
            }
          }
          .block-search {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            .search-item {
              padding: 0;
              font-size: $size-12;
              height: 24px;
              width: 24px;
            }
            svg {
              margin: auto 0;
            }
          }
          hr {
            opacity: 1;
          }
          .drug-list {
            border: 1px solid $line-gray;
            border-radius: 10px;
            .header {
              padding: 8px;
              background-color: $primary-50;
              border-radius: 10px 10px 0 0;
            }
            .body {
              .drug-item {
                padding: 16px 8px;
                .label {
                  display: flex;
                  align-items: center;
                  .name {
                    display: flex;
                    flex: 1;
                  }
                  .btn {
                    padding: 0;
                  }
                  :last-child {
                    display: flex;
                  }
                }
                .more {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .btn {
                    color: $secondary;
                    svg {
                      transform: rotate(-90deg);
                    }
                    svg.more {
                      transform: rotate(90deg);
                    }
                  }
                }
                .more-data {
                  margin-top: 28px;
                  display: flex;
                  flex-direction: column;
                  gap: 16px;
                  .medicine-more {
                    display: flex;
                    align-items: center;
                    .label {
                      display: flex;
                      flex: 1;
                    }
                    :last-child {
                      display: flex;
                    }
                    .data {
                      .badge {
                        padding: 4px 8px;
                        border-radius: 20px;
                      }
                    }
                  }
                }
              }
              .drug-item:not(:last-child) {
                border-bottom: 1px solid $line-gray;
              }
            }
          }
        }
      }
      .block-detail-item {
        border: 1px solid $line-gray;
        border-radius: 10px;
        background-color: #FFF;
        .detail-header {
          height: 60px;
          background-color: $primary-50;
          color: $primary;
          padding: 16px 16px 14px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          font-size: $size-20;
          font-weight: 500;
        }
        .detail-item {
          padding: 16px;
          min-height: 200px;
        }
      }
      .btn-symptom {
        border: 2px solid $surface;
        background-color: $surface;
        border-radius: 10px;
        font-weight: 300;
        font-size: $size-16;
        padding: 12px 16px;
        color: $light-gray;
        > span {
          float: left;
          .label {
            margin-left: 8px;
          }
        }
        > svg {
          color: $primary;
          float: right;
        }
      }
      .btn-symptom:hover, .btn-symptom:active {
        border: 2px solid $primary !important;
        padding: 12px 16px;
      }
      .nav-tabs {
        border-radius: 10px;
        background-color: #FFF;
        .nav-item {
          display: flex;
          flex-direction: column;
          cursor: pointer;
          .nav-link {
            border: unset !important;
            border-radius: 10px;
            color: $light-gray;
            padding: 16px 0;
          }
          .nav-link.active {
            border-radius: 10px;
            color: $primary;
          }
          .nav-link.active:hover {
            color: $primary-500;
          }
        }
        .nav-item:hover, .nav-item:active {
          border-radius: 10px;
          .nav-link {
            color: $primary;
          }
          .nav-link:hover {
            background-color: $surface;
          }
          background-color: $surface;
        }
        .nav-item.active::after {
          content: "";
          border-bottom: 2px solid $primary;
          margin: auto;
          width: 75%;
          margin-top: 1px;
          margin-bottom: -1px;
        }
        hr {
          border-left: 2px solid $line-gray;
          height: 40px;
          margin: auto;
          opacity: 1;
        }
      }
      .tab-content {
        .tab-pane.active {
          display: flex;
          flex-direction: column;
        }
        .tab-pane {
          gap: 20px;
          .drug-selected-total {
            background-color: $danger;
            padding: 2px 10px;
            border-radius: 20px;
          }
        }
      }
    }
  }
  .menu-bar, .menu-top-bar {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding: 0 40px;
    @media (max-width: $screen-lg) {
      padding: 0;
    }
    .block-menu-bar {
      padding: 8px 20px;
      background: #292929;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin: 0;
      @media (max-width: $screen-sm) {
        padding: 5px 0;
        > :first-child, > :last-child {
          padding: 0 0.25rem;
        }
      }
      > :last-child {
        gap: 10px;
      }
      .icon {
        border-radius: 50%;
        padding: 10px;
      }
      .icon.active {
        background-color: $secondary;
        svg {
          color: #FFF !important;
        }
      }
    }
    .block-button-call {
      display: flex;
      align-items: center;
      gap: 20px;
      @media (max-width: $screen-sm) {
        gap: 8px;
        svg {
          height: 24px;
          width: 24px;
        }
      }
      .end-call {
        background: #FF2828;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
        border-radius: 30px;
        width: 100px;
        height: fit-content;
        padding: 8px;
        text-align: center;
        cursor: pointer;
        svg {
          transform: rotate(135deg);
        }
        @media (max-width: $screen-sm) {
          width: 80px;
          svg {
            height: 32px;
            width: 32px;
          }
        }
      }
      .mic, .camera {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
        background: rgba(255, 255, 255, 0.3);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
        border-radius: 30px;
        cursor: pointer;
        @media (max-width: $screen-sm) {
          width: 48px;
          height: 48px;
        }
      }
      .end-call:hover, .mic:hover, .camera:hover {
        opacity: 0.9;
      }
    }
    .block-consult-description {
      padding: 12px 0;
      .btn-link {
        font-size: $size-12;
        color: $light-gray;
      }
      .btn-light {
        color: $primary;
        font-size: $size-10;
        font-weight: 600;
        padding: 8px 20px;
        border: 5px solid $primary;
      }
      .btn-chevron {
        color: #FFF;
        rotate: 90deg;
      }
    }
  }
  .menu-top-bar {
    display: none;
    position: relative;
    width: 100%;
    top: 0px;
    padding: 0;
    @media (max-width: $screen-lg) {
      display: block;
    }
    .btn-link {
      padding: 14px 0;
    }
    .block-menu-bar {
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 0;
    }
    .block-consult-description {
      padding: 0;
      .underline {
        margin: auto;
        width: 75%;
        color: #292929;
      }
    }
    .block-consult-description.active {
      .btn-link {
        color: $secondary;
      }
      .underline {
        color: $secondary;
      }
    }
  }
  textarea.form-control {
    padding: 16px;
    @media (max-width: $screen-md) {
      height: 50px;
      padding: 8px;
    }
  }
  .form-label {
    color: $primary;
  }
}
.modal-drug-add {
  .modal-header {
    background-color: $primary-50;
    padding: 8px 16px;
  }
  .modal-body {
    max-height: 660px;
    overflow: auto;
    .drug-add {
      .btn, h2 {
        display: flex;
        flex: 1;
      }
      h2 {
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
      }
    }
  }
}
.modal-drug-view {
  .modal-header {
    background-color: $primary-50;
    padding: 8px;
  }
  .modal-body {
    max-height: 660px;
    overflow: auto;
    padding: 16px 8px;
    .drug-add {
      .btn, .quantity {
        display: flex;
        flex: 1;
      }
      .quantity {
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
      }
    }
    .drug-list {
      > .header {
        padding: 8px;
        background-color: $primary-50;
        border-radius: 10px 10px 0 0;
      }
      .body {
        .drug-item {
          padding: 16px 8px;
          .label {
            display: flex;
            align-items: center;
            .name {
              display: flex;
              flex: 1;
            }
            .btn {
              padding: 0;
            }
            :last-child {
              display: flex;
            }
          }
          .more {
            display: flex;
            align-items: center;
            justify-content: center;
            .btn {
              color: $secondary;
              svg {
                transform: rotate(-90deg);
              }
              svg.more {
                transform: rotate(90deg);
              }
            }
          }
          .more-data {
            margin-top: 28px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .medicine-more {
              display: flex;
              align-items: center;
              .label {
                display: flex;
                flex: 1;
              }
              .data {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                flex-basis: 50%;
                .badge {
                  padding: 4px 8px;
                  border-radius: 20px;
                }
                .duration {
                  :first-child {
                    display: inline-flex;
                  }
                  display: flex;
                  gap: 24px;
                  margin-bottom: 0;
                  ::marker {
                    color: #C4C4C4;
                  }
                }
              }
            }
          }
        }
        .drug-item:not(:last-child) {
          border-bottom: 1px solid $line-gray;
        }
      }
    }
  }
}
.modal-drug-history {
  .modal-body {
    padding: 0;
    .drug-list {
      .body {
        .history-list {
          background-color: $surface;
          margin: 16px;
          border-radius: 10px;
          .information {
            padding: 8px;
            display: flex;
            > :first-child {
              display: flex;
              flex: 1;
              flex-direction: column;
            }
            > :last-child {
              display: flex;
            }
            .btn {
              transform: rotate(-90deg);
            }
            .btn.active {
              transform: rotate(90deg);
            }
          }
          .header {
            padding: 8px;
            background-color: $primary-50;
            border-radius: 10px 10px 0 0;
            display: flex;
            > :first-child {
              display: flex;
              flex: 1;
            }
            > .badge {
              border-radius: 20px;
            }
          }
        }
      }
    }
  }
}
.modal-confirm-diagnosis {
  .modal-body {
    max-height: 580px;
    overflow-x: auto;
    padding: 0;
    .title {
      text-align: center;
      margin-bottom: 20px;
      h3 {
        font-size: $size-20;
        line-height: 30px;
        margin-bottom: 0;
      }
    }
    .header {
      background-color: $primary-50;
      padding: 8px 0;
    }
    .block-data {
      padding: 0 12px;
    }
  }
  .modal-footer {
    justify-content: center;
    .btn {
      width: 400px;
    }
  }
}
.drug-list {
  > .header {
    padding: 8px;
    background-color: $primary-50;
    border-radius: 10px 10px 0 0;
  }
  .body {
    .drug-item {
      padding: 16px 8px;
      .drug-add {
        justify-content: center;
      }
      .label {
        display: flex;
        align-items: center;
        .name {
          display: flex;
          flex: 1;
        }
        .btn {
          padding: 0;
        }
        :last-child {
          display: flex;
        }
      }
      .more {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
          color: $secondary;
          svg {
            transform: rotate(-90deg);
          }
          svg.more {
            transform: rotate(90deg);
          }
        }
      }
      .more-data {
        margin-top: 28px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .medicine-more {
          display: flex;
          align-items: center;
          .label {
            display: flex;
            flex: 1;
          }
          .data {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            flex-basis: 50%;
            .badge {
              padding: 4px 8px;
              border-radius: 20px;
            }
            .duration {
              :first-child {
                display: inline-flex;
              }
              display: flex;
              gap: 24px;
              margin-bottom: 0;
              ::marker {
                color: #C4C4C4;
              }
            }
          }
        }
      }
    }
    .drug-item:not(:last-child) {
      border-bottom: 1px solid $line-gray;
    }
  }
}
.block-drug-choose {
  min-height: 3.5rem;
  .btn-drug-choose {
    position: absolute;
    bottom: 2.5rem;
    width: calc(50% - 20rem);
    margin: 0 7.5rem;
    @media (max-width: $screen-lg) {
      bottom: 4rem;
      width: calc(100% - 10rem);
      margin: 0 3.5rem;
    }
    @media (max-width: $screen-sm) {
      bottom: 3rem;
      width: calc(100% - 3rem);
      margin: 0;
    }
  }
  .btn-drug-choose.disabled {
    opacity: 1;
  }
}
.dash-center {
  display: inline-block;
  text-align: center;
}
.card-account .action-account {
  color: $secondary-200;
}
.card-account .action-account:hover {
  text-decoration: underline;
}
.navbar-button .dropdown-menu .card-level {
  padding: 16px 16px 12px;
  border-radius: 10px;
}
.card-level.platinum {
  background: linear-gradient(360deg, #CDCCCC 11.74%, #EAEAEA 72.09%);
}
.card-level hr {
  border: solid 1px #fff;
}
.btn-check:focus + .btn-sm, .btn-sm, .btn-sm:focus {
  padding: 7px 12px;
}
.btn-square {
  border-radius: 10px;
}
.btn-square:focus {
  border-radius: 10px;
}
.btn-icon {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border: none;
}
.btn-icon-column {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.btn-rotate-90 {
  transform: rotate(90deg);
}
.btn-rotate-90-r {
  transform: rotate(-90deg);
}
.btn-radius, .text-radius {
  border-radius: 30px;
}
.btn-40, .btn-40:focus {
  padding: 3px 0.75rem;
}
.btn-close, .btn-close:focus {
  padding: 0.25em 0.25em;
  border: none;
}
.btn-link, .btn-link:focus {
  padding: 0.375rem 0.75rem;
  border: none;
}
.btn-primary, .btn-primary.disabled:hover {
  background-color: $primary;
  border-color: $primary;
}
.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:first-child:hover, :not(.btn-check) + .btn-primary:hover, .btn-primary:focus {
  background-color: $primary-600;
  border-color: $primary-600;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: $primary-50;
  border-color: $primary-50;
}
.btn-secondary, .btn-secondary.disabled:hover {
  background-color: $secondary;
  border-color: $secondary;
  color: #fff;
}
.btn-secondary:hover, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:focus {
  background-color: $secondary-600;
  border-color: $secondary-600;
  color: #fff;
}
.btn-outline-primary, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: transparent;
  border: solid 1px $primary;
  color: $primary;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:first-child:hover, :not(.btn-check) + .btn-outline-primary:hover {
  background-color: transparent;
  border-color: $primary-600 !important;
  color: $primary-600;
}
.btn-outline-primary:focus {
  border: solid 1px $primary-600 !important;
  color: $primary-600;
}
.btn-outline-secondary, .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active {
  background-color: transparent;
  border: solid 2px $secondary-200;
  color: $secondary-200;
  padding: 8px 0.75rem;
}
.btn-outline-secondary:hover {
  background-color: transparent;
  border: solid 5px $secondary-100;
  color: $secondary-200;
  padding: 5px 0.75rem;
}
.btn-outline-secondary:focus {
  border: solid 2px $secondary-300;
  color: $secondary-300;
  padding: 8px 0.75rem;
}
.btn-h40, .btn:focus.btn-h40 {
  padding: 3px 0.75rem;
}
.btn-white {
  background-color: #FFF;
  border-color: #FFF;
  color: $primary;
}
.btn-white:hover, .btn-white:active, .btn-white:focus {
  background-color: $primary-600 !important;
  color: #FFF !important;
  border-color: $primary-600 !important;
}
.btn-white:hover {
  border-color: $primary !important;
}
.block-label {
  background: $surface;
  border-radius: 10px;
  padding: 16px;
  .text-value {
    text-align: right;
  }
}
.step-register-doctor {
  .title {
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    @media (max-width: $screen-sm) {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }
  }
  .progressbar-wrapper {
    display: flex;
    width: 100%;
    margin-top: 16px;
    .progressbar {
      width: 600px;
      margin: auto;
      margin-bottom: 0;
      padding-left: 0;
      @media (max-width: $screen-sm) {
        width: 320px;
      }
      .item {
        position: relative;
        float: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;
        @media (max-width: $screen-sm) {
          width: 80px;
        }
        .step-item {
          position: relative;
          text-align: center;
          gap: 10px;
          width: 48px;
          height: 48px;
          border-radius: 30px;
          background-color: #FFF;
          border: 2px solid $light-gray;
          color: $light-gray;
          font-weight: 500;
          font-size: 28px;
          line-height: 38px;
          padding: 3px;
          @media (max-width: $screen-sm) {
            width: 40px;
            height: 40px;
            padding: 0;
          }
        }
        .step-label {
          display: block;
          text-align: center;
          margin: 8px auto 0 auto;
          position: relative;
          z-index: 2;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: $light-gray;
          @media (max-width: $screen-sm) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .item::after {
        width: 80%;
        height: 2px;
        content: "";
        position: absolute;
        border: 2px dashed $light-gray;
        margin-top: 23px;
        margin-left: 90%;
        z-index: -1;
        @media (max-width: $screen-sm) {
          margin-top: 19px;
        }
      }
      .item.active::after {
        border: 2px solid $secondary;
      }
      .item.current::after {
        border: 2px dashed $secondary;
      }
      .item:last-child::after {
        content: unset;
      }
      .item.active {
        .step-item {
          background-color: $secondary;
          border: 2px solid $secondary;
          color: #FFF;
        }
        .step-label {
          color: $secondary;
        }
      }
      .item.current {
        .step-item {
          border: 2px solid $secondary;
          color: $secondary;
          font-weight: 500;
        }
        .step-label {
          color: $secondary;
          font-weight: 600;
          @media (max-width: $screen-sm) {
            font-weight: 500;
          }
        }
      }
    }
  }
  .step-title {
    margin-top: 24px;
    font-weight: 500;
    font-size: $size-20;
    line-height: 30px;
  }
  .step-header {
    font-size: $size-20;
    font-weight: 600;
    color: $secondary;
    padding: 0;
  }
  .block-step-register {
    max-width: 600px;
    padding: 0 10px;
    margin: auto;
    .block-lists-item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 16px;
      .list-item {
        padding: 8px 16px;
        background-color: #FFF;
        border-radius: 10px;
        font-size: $size-16;
        cursor: pointer;
        > .remove {
          margin-left: 8px;
          color: $secondary;
        }
      }
      .list-item:hover, .list-item.active {
        background-color: $primary;
        color: #FFF;
        > .remove {
          color: #FFF;
        }
      }
    }
    .btn-addmore-lang {
      background-color: #FFF;
      border-radius: 10px;
      border-color: #FFF;
      color: $primary;
      padding: 8px;
    }
    .btn-addmore-lang:hover, .btn-addmore-lang:focus {
      background-color: $surface;
      border-color: $surface;
      color: $primary-500;
      padding: 5px;
    }
  }
  .block-kyc-process {
    margin-bottom: 50px;
  }
  .img-register-photo {
    margin-top: 20px;
  }
  .register-photo {
    height: 140px;
  }
  .container-block-doctor {
    box-sizing: border-box;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
    @media (max-width: $screen-md) {
      gap: 16px;
    }
    @media (max-width: $screen-sm) {
      gap: 8px;
    }

    .block-doctor {
      width: 184px;
      height: 184px;
      background-color: #FFF;
      border: 1px solid $primary;
      border-radius: 10px;

      .item-image {
        margin-top: 35px !important;
        text-align: center;
        img {
          width: 80px;
          height: 80px;
        }
      }
      .item-label {
        margin-top: 8px;
        text-align: center;
        font-size: $size-20;
        font-weight: 500;
      }
      @media (max-width: $screen-lg) {
        width: 184px;
        height: 184px;
      }
      @media (max-width: $screen-sm) {
        width: 172px;
        height: 172px;
      }
      @media (max-width: $screen-xs) {
        .item-image {
          margin-top: 25px !important;
          img {
            width: 60px;
            height: 60px;
          }
        }
        .item-label {
          margin-top: 4px;
          font-size: $size-16;
        }
        width: 134px;
        height: 134px;
      }
    }
    .block-doctor:hover {
      background-color: $primary-50;
    }
    .block-doctor.active {
      background-color: $primary-300;
    }
  }
  .block-label {
    background: #FFF;
    border-radius: 10px;
    padding: 16px;
    .text-value {
      text-align: right;
    }
  }
}
.block-square-color {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.block-lists-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  flex-wrap: wrap;
  .list-item {
    padding: 8px;
    background-color: $surface;
    border-radius: 10px;
    font-size: $size-16;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 8px;
    min-width: fit-content;
    > .flag {
      height: 24px !important;
      width: auto !important;
    }
    > .remove {
      color: $secondary;
    }
  }
  .list-item:hover, .list-item.active {
    background-color: $primary;
    color: #FFF;
    > .remove {
      color: #FFF;
    }
  }
}
.block-symptom {
  background: #FFF;
  border-radius: 10px;
  padding: 16px;
  .btn-symptom {
    border: 1px solid $primary;
    border-radius: 10px;
    font-weight: 300;
    font-size: $size-16;
    padding: 12px 16px;
    > span {
      float: left;
      .label {
        margin-left: 8px;
      }
    }
    > svg {
      float: right;
    }
  }
  .btn-symptom:hover, .btn-symptom:active, .btn-symptom:focus {
    border: 5px solid $primary !important;
    padding: 8px 16px;
  }
  .choose {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    .choose-item {
      padding: 4px 8px;
      background-color: $surface;
      border-radius: 10px;
      > .remove {
        margin-left: 8px;
        color: $secondary;
      }
      > .remove {
        cursor: pointer;
      }
    }
  }
  .block-input {
    box-sizing: border-box;
    background: #FFF;
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 12px 0;
    .search.input-group {
      .form-control, .input-group-text {
        border: unset;
        border-radius: unset;
        padding: 0 16px;
      }
      .input-group-text {
        cursor: pointer;
      }
      .input-group-text:hover {
        background-color: $surface;
      }
    }
    .block-symptom-item {
      margin-top: 8px;
      max-height: 240px;
      overflow: auto;
      .item {
        padding: 8px 16px;
        svg {
          float: right;
        }
      }
      .item:hover {
        background-color: $primary-50;
        cursor: pointer;
      }
    }
    .btn-add-symptom {
      background-color: $surface;
      border: unset;
      border-radius: 10px;
      color: $primary;
      padding: 8px 0;
    }
    .btn-add-symptom:hover, .btn.back-add:hover {
      background-color: $line-gray;
      color: $primary-500;
    }
    .btn.back-add {
      background-color: #FFF;
      border: unset;
      border-radius: 10px;
      color: $primary;
      padding: 8px 0;
    }
  }
}
.block-symptom.lang-flag {
  .block-input {
    .search {
      padding: 8px 16px;
      > :first-child {
        flex: 1;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}
.block-dropdown.-error {
  border: 1px solid;
  .block-input {
    border: unset;
  }
}
.block-dropdown {
  background: #FFF;
  border-radius: 10px;
  background-color: $surface;
  .btn-dropdown {
    border: unset;
    border-radius: 10px;
    font-weight: 300;
    font-size: $size-16;
    padding: 8px 16px;
    display: flex;
    .label {
      color: $light-gray;
      width: 95%;
      text-align: left;
    }
    .block-icon {
      width: 5%;
      align-items: flex-end;
    }
    .data-selected {
      width: 95%;
      color: $primary;
      display: flex;
      .code {
        width: 60px;
      }
      .name {
        flex-wrap: wrap;
        text-align: left;
        width: 80%;
      }
      .block-icon {
        width: 40px;
        text-align: right;
        svg {
          color: $secondary;
        }
      }
    }
  }
  .btn-dropdown:hover, .btn-dropdown:active, .btn-dropdown:focus {
    border: unset !important;
  }
  .choose {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    .choose-item {
      padding: 4px 8px;
      background-color: $surface;
      border-radius: 10px;
      > .remove {
        margin-left: 8px;
        color: $secondary;
      }
      > .remove {
        cursor: pointer;
      }
    }
  }
  .block-input {
    box-sizing: border-box;
    background: #FFF;
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 12px 0;
    background-color: $surface;
    .search.input-group {
      .form-control, .input-group-text {
        background-color: $surface;
        border: unset;
        border-radius: unset;
        padding: 0 16px;
      }
      .input-group-text {
        cursor: pointer;
      }
      .input-group-text:hover {
        opacity: 0.2;
      }
    }
    .block-dropdown-item {
      margin-top: 8px;
      max-height: 240px;
      overflow: auto;
      .item {
        display: flex;
        padding: 8px 16px;
        .code {
          width: 60px;
        }
        .name {
          flex-wrap: wrap;
          width: 90%;
        }
        .block-icon {
          width: 10%;
          text-align: right;
          svg {
            color: $secondary;
          }
        }
      }
      .item:hover {
        background-color: $primary-50;
        cursor: pointer;
      }
    }
    .btn-add-dropdown {
      background-color: $surface;
      border: unset;
      border-radius: 10px;
      color: $primary;
      padding: 8px 0;
    }
    .btn-add-dropdown:hover, .btn.back-add:hover {
      background-color: $line-gray;
      color: $primary-500;
    }
    .btn.back-add {
      background-color: #FFF;
      border: unset;
      border-radius: 10px;
      color: $primary;
      padding: 8px 0;
    }
  }
}
.container.dashboard {
  .block-grid-data {
    @media (max-width: $screen-lg) {
      flex-direction: column;
    }
    > :first-child {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .block-flex-gap {
      gap: 20px;
      @media (max-width: $screen-md) {
        flex-direction: column;
      }
    }
  }
}
.block-grid-data {
  display: flex;
  gap: 20px;
  align-items: stretch;
  .block-data {
    flex: 1;
    height: fit-content;
  }
  .block-flex-gap {
    display: flex;
    gap: 20px;
    height: fit-content;
    .block-data {
      height: unset;
    }
    .consult-request-card {
      overflow: unset;
      align-items: center;
      .consult-card {
        width: 100%;
      }
    }
  }
  > :first-child {
    flex-basis: 69.117% !important;
  }
  @media (max-width: $screen-lg) {
    > :first-child {
      flex-grow: 1 !important;
    }
    .block-data {
      flex-grow: 1;
    }
  }
  @media (max-width: $screen-md) {
    flex-direction: column;
  }
}
.block-data {
  background: #FFF;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 20px;
  .title {
    font-size: $size-20;
    font-weight: 500;
    margin-bottom: 16px;
  }
  .consult-card-empty {
    margin: auto;
    padding: 70px 0;
    font-size: $size-12;
    font-weight: 300;
    color: $light-gray;
  }
  .consult-request-card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    overflow: auto;

    @media (max-width: $screen-sm) {
      flex-direction: column;
    }
    .consult-card {
      width: 350px;
      background-color: $surface;
      border-radius: 10px;
      flex: none;
      flex-grow: 0;

      padding: 8px;

      @media (max-width: $screen-sm) {
        width: 100%;
      }

      .header {
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        > .float-left {
          float: left;
        }
        > .float-right {
          float: right;
          :first-child {
            font-weight: 400;
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        width: 100%;
      }
      .footer-button {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
  .consult-waiting-card, .row-content {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (max-width: $screen-lg) {
      gap: 16px;
    }
    .consult-card.medical-records {
      > .header {
        @media (max-width: $screen-sm) {
          flex-direction: column;
        }
      }
    }
    .consult-card {
      padding: 16px;
      background-color: $primary-50;
      border-radius: 10px;

      .block-img-profile {
        background-color: $surface;
        width: 40px;
        height: 40px;
        .icon-profile {
          width: 24px;
          margin-top: 0;
        }
      }

      > .header {
        display: flex;
        flex-direction: row;
        .content-right {
          display: flex;
          justify-content: right;
          flex: 1;
          align-items: center;
        }
      }

      > .body, > .detail {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 12px;
        font-size: $size-12;
        font-weight: 300;
        line-height: 16px;
        .detail-item {
          display: flex;
          .item-label {
            flex: 1;
            font-weight: 500;
          }
          .item-data {
            flex: 1;
            display: flex;
            justify-content: right;
          }
          .item-image {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            img {
              background-color: $background;
              object-fit: contain;
              height: 52px;
              width: 52px;
              border-radius: 10px;
            }
          }
        }
      }
      > .body {
        gap: 12px;
      }
      > .detail {
        gap: 8px;
      }
      .footer-button {
        margin: auto;
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
        @media (max-width: $screen-lg) {
          gap: 8px;
          max-width: 408px;
        }
      }
    }
  }
  .row-content {
    gap: 16px;
    .block-image {
      width: 40px;
      height: 40px;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: auto;
      }
    }
    .block-lists-item {
      gap: 8px;
    }
  }
  .patients-medical-record {
    gap: 16px;
    .consult-card {
      > .detail {
        gap: 16px;
        .drug-list {
          .body {
            .drug-item {
              padding: 0;
              display: flex;
              flex-direction: column;
              gap: 8px;
            }
          }
        }
      }
    }
  }
  .block-body-data {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .card {
      background-color: $surface;
      border-radius: 10px;
      margin-bottom: 0;
      cursor: pointer;
      .card-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px;
        .top-right {
          justify-content: right;
          display: flex;
          gap: 8px;
        }
        .mid-data {
          display: flex;
          align-items: center;
          :last-child {
            display: flex;
            flex: 1;
            justify-content: right;
          }
        }
      }
    }
  }
}
.container.settings {
  .top-menu {
    display: flex;
    gap: 16px;
    margin-bottom: 32px;
    flex-wrap: wrap;
    .btn {
      border-radius: 10px;
    }
  }
  .notification {
    .block-data {
      @media (max-width: $screen-md) {
        img {
          width: 415px;
        }
      }
      @media (max-width: $screen-sm) {
        img {
          width: 350px;
        }
      }
    }
  }
}
.container.close-account, .custom-modal.close-account {
  .block-patient {
    gap: 16px;
    .block-img-profile {
      width: 60px;
      height: 60px;
    }
  }
}
.container.wallet {
  .block-data {
    > .selected {
      display: flex;
      gap: 7.5%;
      justify-content: center;
      @media (max-width: $screen-md) {
        gap: 15%;
      }
      @media (max-width: $screen-sm) {
        gap: 10%;
      }
      @media (max-width: $screen-xs) {
        gap: 10px;
        flex-wrap: wrap;
      }
      .btn {
        padding: 8px 30px;
        @media (max-width: $screen-md) {
          padding: 8px 20px;
        }
        @media (max-width: $screen-sm) {
          padding: 8px 14px;
        }
        background-color: $surface;
        border-radius: 10px;
        text-align: center;
      }
      .btn:hover {
        background-color: $primary-500;
        color: $primary-50;
      }
      .btn.selected {
        background-color: $primary;
        color: $color-white
      }
      .btn.selected:hover {
        background-color: $primary-200;
        color: $primary-500
      }
    }
    .lists {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .list-item {
        padding: 8px;
        background-color: $surface;
        border-radius: 10px;
        .label {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        .deposit {
          color: $success;
        }
        .deposit::before {
          content: '+';
        }
        .withdraw, .cancel {
          color: $danger;
        }
        .withdraw::before, .cancel::before {
          content: '-';
        }
      }
    }
  }
}
.profile-info {
  padding: 0 20px;
  .block-img-profile {
    height: 100px;
    width: 100px;
    margin-top: 32px;
    margin-bottom: 32px;
    .icon-profile {
      margin-top: 0;
    }
    .remove {
      position: absolute;
      top: 10px;
      right: 0;
      color: $danger;
      cursor: pointer;
    }
  }
  .general-info > .text-inline {
    gap: 16px;
  }
  .block-data {
    .general-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
.block-data.social-connection {
  .social {
    .text-inline {
      gap: 16px;
    }
  }
}
.block-button {
  margin: auto;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-right: 0;
  flex-wrap: wrap;
  justify-content: right;
  @media (max-width: $screen-lg) {
    max-width: unset;
  }
  @media (max-width: 575px) {
    justify-content: center;
  }
}
.status-pending {
  color: $warning;
  font-weight: 600;
}
.status-appointment {
  border-radius: 20px;
  padding: 4px 8px;
  color: $color-white;
  font-weight: 300;
  font-size: $size-12;
  width: max-content;
}
.status-appointment.pending {
  background-color: $dark-gray;
}
.status-appointment.incomplete {
  background-color: $warning;
}
.status-appointment.confirm {
  background-color: $primary;
}
.status-appointment.incoming {
  background-color: $primary;
}
.status-appointment.complete {
  background-color: $success;
}
.status-appointment.cancel {
  background-color: $danger;
}
.block-lang-flag {
  display: flex;
  align-items: center;
  :first-child {
    margin-right: 8px;
  }
}
.label-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  > .text {
    max-width: 310px;
  }
  > .text-date {
    font-weight: 400;
  }
  .icon {
    width: 16px;
  }
}
.block-patient {
  display: flex;
  gap: 8px;
  .block-img-profile {
    width: 48px;
    height: 48px;
    margin: 0;
    .icon-profile {
      margin-top: 7px;
    }
  }
  .block-detail-profile {
    .title {
      font-size: $size-16;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
    }
    .detail {
      display: flex;
      align-items: flex-start;
      gap: 25px;
      font-size: $size-12;
      flex-direction: row;
      .detail-item {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        .label {
          font-weight: 500;
        }
      }
    }
  }
}
.block-doctor-schedule {
  padding: 0px;
  border-radius: 10px;
  text-align: center;
}
.table-doctor-schedule {
  border-radius: 10px;
  color: #FFF;
  overflow: hidden;
  font-weight: 300;
  th {
    font-weight: 600;
  }
  th, td {
    border-bottom: unset;
    vertical-align: middle;
  }
  .detail-th {
    display: block;
  }
  .detail {
    background-color: $primary-700;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    > .btn {
      border-radius: 10px;
    }
  }
  > tbody > tr:nth-of-type(odd) > * {
    background-color: $primary-200;
  }
  > tbody > tr:nth-of-type(odd) > td.detail {
    background-color: #FFF;
  }
  > tbody > tr:nth-of-type(even) > * {
    background-color: $primary-400;
  }
  > tbody > tr:nth-of-type(even) > td.detail {
    background-color: $surface;
  }
}
section.main {
  margin-top: 75px;
  min-height: 400px;
}
section.download {
  background-color: $primary-700;
  color: #fff;
  padding: 25px 0;
}
section.download .description {
  font-size: 20px;
}
section.download a {
  min-width: 400px;
}
.carousel-control-next, .carousel-control-prev {
  width: 10%;
}
.carousel-indicators .active {
  background-color: $secondary-200;
}
.carousel-home .carousel-caption {
  left: 130px;
  right: auto;
  top: 195px;
  text-align: left;
}
.carousel-home .carousel-caption h5 {
  font-size: 80px;
  margin-bottom: 1.5rem;
}
.carousel-home .carousel-caption p {
  font-size: 28px;
  max-width: 659px;
  line-height: 150%;
  margin-bottom: 1.5rem;
}
.carousel-home .carousel-caption .btn {
  height: 60px;
  min-width: 400px;
  font-size: 20px;
  display: inline-grid;
  align-items: center;
  position: absolute;
  bottom: 35%;
}
.block-home {
  padding: 120px 0 220px;
}
.block-home .medical-services-title {
  font-size: 36px;
  text-align: center;
}
.block-home .medical-services-desc {
  font-size: 20px;
  max-width: 700px;
  margin: 0.75em auto 80px;
  text-align: center;
  line-height: 30px;
}
.block-home .service {
  margin-bottom: 120px;
  text-align: center;
}
.block-home .service h3 {
  margin-top: 1.5rem;
  font-size: 28px;
}
.modal-custom {
  max-width: 1030px;
}
.modal-custom .modal-content, .modal-content {
  border: none;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.modal-content {
  border-radius: 10px;
}
.change-contact {
  min-width: 600px;
}
.sidebar {
  .nav-item {
    width: 100%;
  }
  .nav-link {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 16px 0;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }
  .nav-link:hover, .nav-link.active {
    color: $secondary-50;
    background-color: $primary-700;
    border-radius: 10px;
  }
  .nav-link:focus {
    background-color: $primary-700;
    color: $secondary-50;
  }
  .block-img-profile {
    margin-bottom: 10px;
  }
  .label-profile {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }
  .logo {
    display: none;
  }
  .btn.logo-icon {
    display: block;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 13px;
  }
  .label-inline {
    display: none !important;
  }
  .btn-signout {
    display: none;
  }
  .block-img-profile {
    height: 50px;
    width: 50px;
    margin-bottom: 30px;
    svg {
      margin: 5px;
      height: 38px;
      width: 25px;
    }
  }
  .input-search {
    .form-control {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      background: $surface;
      border-color: $line-gray;
    }
    .input-group-text {
      color: $light-gray;
      background: $surface;
      border-color: $line-gray;
    }
    .input-group .input-group-text {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      border-color: $line-gray;
    }
  }
  @media (max-width: $screen-md) {
    height: 100vh;
  }
}
.block-banner {
  .action-login {
    padding: 16px 0 16px;
  }
  .register-footer {
    height: 100px;
  }
  .checkbox-text {
    color: $secondary;
    font-style: normal;
    font-weight: 600;
  }
  .login-footer {
    background-color: $primary-600;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    color: $secondary-50;
    padding: 20px 0;
    ._label-text {
      margin-top: 9px;
      margin-bottom: 8px;
      font-size: 20px;
      text-align: center;
      display: inline-block;
    }
    .btn {
      width: 200px;
    }
  }
}
.block-form {
  margin: 5px auto 0 auto;
  border-radius: 30px;
  background-color: #fff;
  z-index: 9;
  text-align: center;
  .login-title {
    line-height: 30px;
    font-weight: 300;
  }
  .img-logo {
    height: 80px;
    margin: 8px auto 0 auto;
    padding: 12px 0;
  }
}
.react-toggle {
  .react-toggle-track {
    width: 52px;
    height: 28px;
    border-radius: 32px;
    background-color: $light-gray;
  }
  .react-toggle-thumb {
    top: 2px;
    left: 2px;
    width: 24px;
    height: 24px;
    border-radius: 20px;
    gap: 8px;
    border: unset;
  }
}
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $light-gray;
}
.react-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: $primary;
}
.react-toggle.react-toggle--checked {
  .react-toggle-track {
    background-color: $primary;
  }
  .react-toggle-thumb {
    left: 26px;
    border: unset;
  }
}
.input-group .input-group-text, .register .input-group .input-group-text, .forgot-password .input-group .input-group-text, .react-datepicker-wrapper .input-group .input-group-text {
  background-color: #fff;
  border-color: $line-gray;
  padding: 7px 1rem;
  border-radius: 8px;
  line-height: 24px;
}
.input-group .input-group-text.view-password, .register .input-group .input-group-text.view-password, .forgot-password .input-group .input-group-text.view-password {
  padding-left: 0;
  color: $dark-gray;
}
.input-group .input-group-text.view-password svg, .register .input-group .input-group-text.view-password svg, .forgot-password .input-group .input-group-text.view-password svg {
  cursor: pointer;
}
.input-group {
  > * {
    transition: 300ms;
  }
}
.input-group:focus-within, .input-group:focus {
  > * {
    border-color: $primary-900 !important;
    border: 1px solid $primary-900;
    transition: 300ms;
  }
}
.custom-input-number.input-group:focus-within, .custom-input-number.input-group:focus {
  > * {
    border-color: $surface !important;
    transition: 300ms;
  }
}
.input-group input:not(:first-child), .register .input-group input, .forgot-password .input-group input {
  border-left: none;
  padding-left: 0;
}
.link-forgot-password {
  font-size: 12px;
  cursor: pointer;
  color: $secondary;
}
.link-forgot-password:hover {
  text-decoration: underline;
}
.react-tel-input .form-control {
  border-radius: 4px;
  width: 100% !important;
  height: calc(1.5em + 0.9rem + 2px) !important;
  border: 1px solid #ced4da;
}
.react-tel-input .form-control:focus {
  border-color: $secondary-200;
}
.react-tel-input .form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.react-tel-input .invalid-number-message {
  top: -11px;
  padding: 0 5px;
}
.form-control:focus, .form-select:focus, .btn:focus, .btn.focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}
.react-tel-input, .block-form .react-tel-input {
  display: flex;
}
.react-tel-input .form-control, .block-form .react-tel-input .form-control {
  height: 40px !important;
  margin-left: 110px;
  padding: 8px 16px;
  border: 1px solid $secondary;
  border-radius: 20px;
}
.react-tel-input .flag-dropdown, .block-form .react-tel-input .flag-dropdown {
  background-color: transparent;
  border: none;
}
.react-tel-input .selected-flag, .block-form .react-tel-input .selected-flag {
  background-color: $secondary-200;
  border-radius: 20px;
  width: 100px;
  padding: 0 40px;
}
.react-tel-input .flag-dropdown.open .selected-flag, .block-form .react-tel-input .flag-dropdown.open .selected-flag {
  background-color: $secondary-200;
  border-radius: 20px;
}
.react-tel-input .selected-flag .arrow, .block-form .react-tel-input .selected-flag .arrow {
  border-top-color: #fff;
}
.react-tel-input .selected-flag .arrow.up, .block-form .react-tel-input .selected-flag .arrow.up {
  border-bottom-color: #fff;
}
.react-tel-input .invalid-number-message, .block-form .react-tel-input .invalid-number-message {
  left: 135px;
  border-radius: 4px;
  color: $danger;
  font-size: 12px;
}
.block-form .invalid-feedback {
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0 0;
}
.block-form .invalid-feedback.mobile {
  margin: 5px 0 0 110px;
}
.block-form .form-control, .account .form-control, .account .form-select, .setting .form-control, .setting .form-select {
  border-color: $secondary-200;
}
.block-form .input-group:focus-within, .block-form .form-control:not(.input-group input):focus-within, .account .form-control:focus-within, .account .form-select:focus-within, .setting .form-control:focus-within, .setting .form-select:focus-within {
  border-radius: 30px;
  -webkit-transition: box-shadow ease-in-out .15s;
  transition: box-shadow ease-in-out .15s;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(196 155 85 / 25%) !important;
  box-shadow: 0 0 0 0.2rem rgb(196 155 85 / 25%) !important;
}
.input-group .input-group-text.-error, .register .input-group .input-group-text.-error, .forgot-password .input-group .input-group-text.-error, .react-datepicker-wrapper .input-group .input-group-text.-error {
  border-color: $danger;
}
.block-form .form-control.-error, .account .form-control.-error, .account .form-select.-error, .setting .form-control.-error, .setting .form-select.-error, .form-control.-error, .block-dropdown.-error {
  border-color: $danger;
}
.input-group > :not(:first-child):not(:last-child) {
  border-right: none;
}
.heading-line {
  position: relative;
}
.heading-line:before {
  top: 47%;
  content: "";
  position: absolute;
  left: auto;
  right: 0;
  width: 100%;
  height: 0;
  border: 1px solid $line-gray;
  overflow: hidden;
}
.heading-line .title-section {
  position: relative;
  display: table;
  margin: 0 auto;
  background-color: #fff;
  color: $light-gray;
  letter-spacing: 0.02em;
  padding: 0 10px;
}
.login-with {
  span {
    margin: 0 10px;
    cursor: pointer;
  }
  button {
    padding: 0 10px;
  }
  button.active img {
    width: 52px;
    border: 2px solid $primary-400;
    border-radius: 50%;
    background-color: $primary-400;
  }
  button:focus {
    padding: 0 10px;
    box-shadow: none !important;
  }
}
.login-with span.active img {
  width: 52px;
  border: 2px solid $primary-400;
  border-radius: 50%;
  background-color: $primary-400;
}
.Toastify__toast {
  font-family: 'Noto Sans Thai', sans-serif;
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 12px;
}
.Toastify__toast-container {
  width: 555px;
}
.Toastify__close-button--light {
  background: #9CA8C7;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  opacity: 0.7;
  color: #fff;
}
.Toastify__toast-theme--colored {
  padding: 10px 12px;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: $success;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: $warning;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: $danger;
}
.Toastify__toast-body {
  .alert-block {
    gap: 16px;
  }
  .alert-text-block {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0;
  }
  .Toastify__toast-icon {
    width: 48px;
    margin-inline-end: unset;
  }
}
.Toastify__close-button > svg {
  width: 13px;
  display: block;
  margin: 0 auto;
}
.Toastify__toast-theme--colored {
  .Toastify__toast-body {
    gap: 16px;
    .alert-block {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}
.Toastify__toast-body .alert-time {
  font-family: 'Noto Sans Thai', sans-serif;
  position: absolute;
  right: 8px;
  bottom: 8px;
  font-size: 11px;
  color: $light-gray;
  line-height: 15px;
}
.Toastify__toast-body .alert-icon {
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.Toastify__toast-body .alert-icon > svg {
  margin: 0 auto;
}
.Toastify__toast-body .alert-title {
  font-weight: bold;
  line-height: 24px;
}
.Toastify__toast-body .alert-message {
  font-size: 12px;
  color: #000;
  word-break: break-all;
  white-space: pre-line;
  line-height: 18px;
}
.Toastify__toast-theme--colored .Toastify__toast-body .alert-message {
  color: #fff;
}
.Toastify__toast-body a {
  text-decoration: none;
}
.form-check-label {
  position: relative;
  cursor: pointer;
  margin: 0;
  user-select: none;
}
.form-check-input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark-checkbox, .checkmark-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 6px;
  border: 2px solid $primary;
}
.checkmark-radio {
  border-radius: 50%;
}
.form-check-label .checkmark-checkbox::after, .form-check-label .checkmark-radio.check::after {
  content: "";
  position: absolute;
  display: none;
  border: solid #fff;
  transform: rotate(45deg);
  border-color: #FFF;
  left: 7px;
  top: 2px;
  width: 6px;
  height: 13px;
  border-width: 0 2px 2px 0;
  border-radius: 0;
}
.form-check-label .checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $primary;
}
.navbar-button .form-check-label .checkmark-radio:after, .form-check-label .checkmark-radio.check:after {
  background: none;
}
.form-check-label input:checked ~ .checkmark-radio.check {
  background-color: $primary-900;
}
.form-check-label.tertiary .checkmark-checkbox, .form-check-label.tertiary .checkmark-radio {
  border-color: $secondary-200;
}
.form-check-label.tertiary input:checked ~ .checkmark-radio.check {
  background-color: $secondary-200;
}
.form-check-label .checkmark-radio.check::after {
  border-color: #fff;
  left: 7px;
  top: 2px;
  width: 6px;
  height: 13px;
  border-width: 0 2px 2px 0;
}
.form-check-label input:checked ~ .checkmark-checkbox:after, .form-check-label input:checked ~ .checkmark-radio:after {
  display: block;
}
.form-check-label input:checked ~ .checkmark-checkbox {
  background-color: $primary;
}
.form-check-label span.checkbox-text {
  padding: 0 0 0 32px;
}
.form-check-label span.radio-text {
  padding: 0 30px;
}
.account-image {
  position: relative;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background-color: $primary-50;
  width: 80px;
  height: 80px;
  padding: 22px;
  margin-top: 1rem;
}
.account-image .icon-plus {
  position: absolute;
  right: 2px;
  bottom: 2px;
}
.account-image input, .block-img-profile input {
  position: absolute;
  outline: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.account-image img {
  border-radius: 50%;
  height: 80px;
  object-fit: cover;
}
.required > label::after {
  content: ' *';
  color: red;
}
.form-label, .col-form-label {
  margin-bottom: 0.25rem;
  font-weight: 600;
}
.form-text {
  display: block;
  margin-top: 0;
  font-size: $size-12;
  font-weight: 300;
  margin-bottom: 0.25rem;
}
.invalid-feedback {
  font-size: 12px;
  color: $danger;
}
.calendar-component {
  ._header {
    ._month-select {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      padding: 0 12.5px;
      .dropdown {
        display: flex;
        flex-shrink: 1;
        color: $primary;
        padding: 8px;
        border-radius: 10px;
        padding-right: 36px;
        background-color: $primary-50;
        border: unset;
        font-family: 'Noto Sans Thai', sans-serif;
        font-style: bold;
        font-size: $size-14;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /* Some browsers will not display the caret when using calc, so we put the fallback first */
        background: url(../images//arrow-down.png) $primary-50 no-repeat calc(100% - 1rem) !important;
        /* Better placement regardless of input width */
      }
    }
    .custom-group-btn {
      display: flex;
      gap: 4px;
      .btn-link {
        color: $secondary;
      }
    }
    ._day-names {
      display: flex;
      gap: 2px;
      align-items: center;
      justify-content: center;
      font-family: 'Noto Sans Thai', sans-serif;
      font-style: bold;
      ._day-name {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        padding: 8px 2px;
        width: 36px;
        text-align: center;
      }
    }
  }
  ._month-display {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    justify-content: center;
    ._week {
      display: flex;
      flex-direction: row;
      gap: 2px;
      align-items: center;
      justify-content: center;
      ._day {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 36px;
        height: 36px;
        font-size: $size-12;
        font-weight: 400;
        padding: 8px;
        color: $light-gray;
        cursor: default;
      }
      ._day.active {
        cursor: pointer;
        color: $primary;
      }
      ._day._selected {
        border-radius: 50%;
        background-color: $primary;
        color: $color-white;
      }
      ._day::after {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        bottom: 4px;
      }
      ._day.booking::after {
        background-color: $danger;
      }
    }
  }
}
.react-datepicker-wrapper {
  width: 100% !important;
  .react-datepicker__input-container {
    .input-group.form-control {
      input {
        min-width: 100px;
      }
    }
    .react-datepicker__close-icon {
      right: 2.2rem;
      top: 12px;
      height: unset;
    }
  }
}
.react-datepicker-popper {
  z-index: 3;
  .react-datepicker {
    font-family: 'Noto Sans Thai', sans-serif;
    font-size: $size-12;
    border: none;
    background-color: $surface;
    color: $primary-900;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 16px 0;
  }
}
.react-datepicker__header {
  font-family: 'Noto Sans Thai', sans-serif;
  font-style: bold;
  background-color: $surface;
  border: none;
  color: $primary-900;
  padding-bottom: 0;
  .react-datepicker__day-names {
    margin-top: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }
  .react-datepicker__day-name {
    font-size: $size-16;
    font-weight: 600;
    line-height: 24px;
    padding: 8px;
    width: 35px;
  }
}
.react-datepicker__header.react-datepicker__header--custom {
  .custom-display-month-select {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 0 12.5px;
    .dropdown {
      display: flex;
      flex-shrink: 1;
      color: $primary;
      padding: 8px;
      border-radius: 10px;
      padding-right: 36px;
      background-color: $primary-50;
      font-size: $size-16;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* Some browsers will not display the caret when using calc, so we put the fallback first */
      background: url(../images//arrow-down.png) $primary-50 no-repeat calc(100% - 1rem) !important;
      /* Better placement regardless of input width */
    }
    .custom-group-btn {
      .btn-link {
        color: $secondary;
      }
    }
  }
}
.react-datepicker__month {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  .react-datepicker__week {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    .react-datepicker__day {
      .pre-active {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 6px;
      }
      ::after {
        content: "";
        position: relative;
        margin: auto;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
      .active::after {
        background-color: $danger;
      }
    }
    .react-datepicker__day {
      margin: 0;
      width: 40px;
      height: 40px;
      font-size: $size-12;
      font-weight: 400;
      line-height: 16px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.react-datepicker__header .btn-link:hover {
  color: $secondary-300;
}
.react-datepicker__header span.label {
  display: inline-block;
  font-size: 16px;
  color: $secondary-200;
}
.react-datepicker__header .dropdown {
  font-size: 14px;
  color: $secondary-200;
  border: none;
  padding: 2px 5px;
}
.react-datepicker__header .dropdown:focus-visible {
  outline: none;
}
.react-datepicker__input-container .input-group input {
  border: none;
  padding: 7px 0.75rem !important;
  flex: 1;
  background-color: transparent;
  color: $primary-900;
}
.react-datepicker__input-container .input-group input:focus-visible {
  outline: none;
}
.react-datepicker__input-container .input-group .input-group-text {
  border: none;
  padding: 7px 0.75rem;
  color: $primary-900;
}
.react-datepicker__day {
  color: $primary-900;
}
.react-datepicker__day:hover {
  border-radius: 50%;
  background-color: $primary-50;
}
.react-datepicker__current-month {
  color: $secondary-200;
}
.react-datepicker__day--outside-month {
  color: $dark-gray;
}
.react-datepicker__navigation-icon::before {
  border-color: $secondary-200;
}
.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--ke {
  border-radius: 50%;
  background-color: $primary-900;
  color: #fff;
}
.react-datepicker__day--keyboard-selected:hover {
  color: $primary-900;
}
.react-datepicker__navigation--previous {
  top: 16px;
  left: 10px;
}
.react-datepicker__navigation--next {
  top: 16px;
  right: 10px;
}
.react-datepicker__triangle {
  display: none;
}
select:invalid {
  color: $light-gray;
}
.dropdowns {
  color: $primary;
  padding: 8px;
  border-radius: 10px;
  padding-right: 40px;
  background-color: $surface;
  border: unset;
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: $size-16;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url(../images//arrow-down.png) $background no-repeat calc(100% - 1rem) !important;
  /* Better placement regardless of input width */
}
.dropdowns.-error {
  border: 1px solid $danger;
}
.dropdowns:hover {
  cursor: pointer;
}
.img-service {
  height: 215px;
  position: relative;
}
.img-service img {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
}
.home-title {
  font-size: 36px;
  margin-bottom: 1rem;
}
.card.custom-card {
  .card-header {
    background-color: $primary-50;
  }
}
.card {
  border-radius: 10px;
  border: 1px solid $surface;
  margin-bottom: 20px;
  @media (max-width: $screen-sm) {
    margin-bottom: 16px;
  }
  .card-header {
    border: none;
    padding: 12px 16px;
    background-color: $background;
    font-size: $size-20;
    font-weight: 500;
    line-height: 30px;
  }
  .card-body {
    .list-group {
      margin: 0 !important;
    }
    .block-patient {
      gap: 16px;
      .block-img-profile {
        width: 80px;
        height: 80px;
        .icon-profile {
          margin-top: 0;
          width: 42px;
          height: 48px;
        }
      }
      .block-detail-profile {
        margin: auto 0;
        .title {
          font-size: $size-20;
          line-height: 30px;
        }
        .detail {
          .detail-item {
            font-size: $size-16;
            .label {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .card-body.consult-success {
    .block-patient {
      gap: 16px;
      .block-img-profile {
        width: 40px;
        height: 40px;
        .icon-profile {
          margin-top: 5px;
        }
        svg {
          width: $size-20;
        }
      }
      .block-detail-profile {
        .title {
          font-size: $size-16;
          line-height: 24px;
        }
        .detail {
          .detail-item {
            font-size: $size-12;
            line-height: $size-16;
            .code {
              color: $secondary;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .list-group {
    margin: 0 16px 16px 16px;
    .list-group-item.flex-column {
      >:last-child {
        * {
          margin-left: unset;
        }
      }
    }
    .list-group-item {
      border: unset;
      border-bottom: 1px solid $line-gray;
      padding: 1rem 0;

      display: flex;
      align-items: center;
      @media (max-width: $screen-sm) {
        flex-direction: column;
        align-items: start;
      }
      >:last-child:not(:first-child) {
        display: flex;
        justify-content: right;
        flex: 1;
        > * {
          margin-left: auto;
        }
        @media (max-width: $screen-sm) {
          margin-left: auto;
        }
      }
      >:last-child.item-image > .image {
        margin-left: unset;
      }

      .item-image {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        flex-wrap: wrap;
        img {
          background-color: $background;
          object-fit: contain;
          height: 52px;
          width: 52px;
          border-radius: 10px;
        }
      }
    }
    .list-group-item.column {
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
      >:last-child {
        margin-left: unset;
        justify-content: normal;
      }
    }
    >:last-child {
      border: none;
    }
  }
}
.doctor-list .card, .consult-program .card {
  border-radius: 20px;
  padding: 1rem;
  border: solid 1px $line-gray;
  margin-bottom: 1.5rem;
}
.doctor-list .card.show {
  padding: 1rem 1rem 0.646rem;
}
.doctor-list a.card:hover, .doctor-list a.card:focus, .consult-program a.card:hover, .consult-program a.card:focus {
  color: $primary-900;
}
.doctor-list .card .card-detail {
  padding: 0 0 8px;
  text-align: center;
}
.doctor-list .card .card-detail .fev-star {
  position: absolute;
  display: flex;
  right: 16px;
  top: 12px;
  align-items: center;
}
.doctor-list .card .card-action {
  border-top: solid 1px $line-gray;
  padding: 1rem 0 0;
}
.doctor-list .card.show .card-action {
  padding: 0.605rem 0 0;
}
.doctor-list .card .card-action .consult-btn, .doctor-list .card.show .card-action .consult-text {
  display: none;
}
.doctor-list .card.show .card-action .consult-btn {
  display: flex;
}
.consult-program .card {
  padding: 0;
}
.consult-program .img-wrap {
  max-height: 200px;
  min-height: 200px;
  background-color: $line-gray;
  border-radius: 20px 20px 0 0;
}
.consult-program .img-wrap img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}
.consult-program.pagination ul {
  list-style: none;
  display: inline-flex;
  margin: 0;
}
.consult-program.pagination ul li {
  padding: 0 10px;
}
.consult-program.pagination ul li:hover, .consult-program.pagination svg:hover {
  color: $secondary-200;
  cursor: pointer;
}
.consult-program.pagination ul li.active {
  color: $secondary-200;
  cursor: default;
}
.consult-program.pagination svg.disabled {
  color: $light-gray;
  cursor: default;
}
.img-mobile-app {
  margin-left: -50px;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-ellipsis.line-clamp-1 {
  -webkit-line-clamp: 1;
}
.doctor-profile {
  padding-top: 40px;
  padding-bottom: 150px;
}
.doctor-profile .card {
  border-radius: 20px;
  border: none;
}
.doctor-profile img.avatar, .consult img.avatar, .payment img.avatar, .history img.avatar {
  width: 262px;
  height: 262px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.consult img.avatar.avatar-60, .payment img.avatar.avatar-60, .history img.avatar.avatar-60 {
  width: 60px;
  height: 60px;
  margin: 0;
}
.nav-tabs {
  border: none;
}
.nav.nav-tabs.custom-tabs {
  margin-bottom: 20px;
  border-radius: 28px;
  padding: 8px;
  color: $primary;
  gap: 8px;
  .nav-item {
    .nav-link {
      font-family: 'Noto Sans Thai', sans-serif;
      font-style: bold;
      font-size: $size-16 !important;
      font-weight: 400 !important;
      line-height: 24px;
      color: $primary;
      border: 1px solid $surface;
      border-radius: 20px;
      cursor: pointer;
    }
    .nav-link.active {
      font-family: 'Noto Sans Thai', sans-serif;
      font-style: bold;
      border: 1px solid $primary;
      background-color: $primary;
      color: $color-white;
    }
  }
  .nav-item:hover {
    background-color: $bg-surface;
    .nav-link {
      font-weight: 600 !important;
      border-color: $primary;
    }
    .nav-link.active {
      background-color: $bg-surface;
      color: $primary;
    }
  }
}
.doctor-profile .fev-star {
  display: flex;
  justify-content: flex-end;
}
.doctor-profile .bage-price {
  background-color: $primary-50 !important;
  color: $primary-700;
  border-radius: 10px;
  padding: 4px 8px;
  font-size: 20px;
}
.doctor-profile .action button, .payment .action .btn {
  display: inline-flex;
  justify-content: center;
  min-width: 280px;
  font-size: 20px;
  padding: 10px;
}
.doctor-profile .nav-tabs, .account .nav-tabs, .setting .nav-tabs {
  background-color: #fff;
  border-radius: 10px;
}
.doctor-profile .nav-tabs .nav-link, .account .nav-tabs .nav-link, .setting .nav-tabs .nav-link {
  padding: 8px 8px 8px 20px;
  margin: 0 0 8px;
  color: #000;
  border: none;
}
.doctor-profile .nav-tabs .nav-item:last-child .nav-link, .account .nav-tabs .nav-item:last-child .nav-link, .setting .nav-tabs .nav-item:last-child .nav-link {
  margin-bottom: 0;
}
.doctor-profile .nav-tabs .nav-link:focus, .doctor-profile .nav-tabs .nav-link:hover, .account .nav-tabs .nav-link:focus, .account .nav-tabs .nav-link:hover, .setting .nav-tabs .nav-link:focus, .setting .nav-tabs .nav-link:hover {
  border-radius: 5px;
  border: none;
  background-color: $primary-50;
  cursor: pointer;
}
.doctor-profile .nav-tabs .nav-link.active, .account .nav-tabs .nav-link.active, .setting .nav-tabs .nav-link.active {
  font-family: 'Noto Sans Thai', sans-serif;
  font-style: bold;
  border-radius: 5px;
  background-color: $primary-400;
  color: #fff;
  border: none;
}
.doctor-profile .tab-content .card {
  border-radius: 10px;
}
.consult img.avatar {
  width: 120px;
  height: 120px;
  margin: 30px auto 1.5rem;
}
.telemed-schedules {
  border-bottom: solid 1px $line-gray;
  padding: 8px 0;
  margin: 0;
  min-height: 65px;
}
.schedule div:last-child .telemed-schedules {
  border: none;
}
.telemed-schedules .col {
  padding: 0;
}
.telemed-schedules .list-time {
  text-align: right;
}
.list-reviews {
  margin: 0;
  border-bottom: solid 1px $line-gray;
  padding: 16px 0;
}
.list-reviews .avatar {
  display: inline-block;
  background-color: $primary-50;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  padding: 15px;
  text-align: center;
}
.list-reviews .avatar img {
  object-fit: cover;
  width: 100%;
  border-radius: 50%;
}
.commen-problems .badge {
  margin: 0 20px 1rem 0;
  padding: 8px 16px;
  border-radius: 22px;
  background-color: $secondary-50 !important;
  color: $primary-900;
  font-size: 20px;
  cursor: pointer;
}
.list-assessment {
  margin-top: 45px;
}
.list-assessment .assess-item {
  position: relative;
  background-color: $primary-800;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  padding: 20px;
  height: 200px;
  text-align: center;
  cursor: pointer;
}
.list-assessment .assess-item:hover {
  background-color: $primary-700;
}
.list-assessment .assess-item > div {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
}
.list-assessment .assess-item > div > p {
  margin-top: 1rem;
  color: #fff;
  font-size: 20px;
}
.event .banner {
  background: #FFFFFF url(../images/event.svg) no-repeat top right;
  border-radius: 10px;
  padding: 2.4rem;
  margin-bottom: 60px;
}
.event .banner h3 {
  text-align: center;
  font-size: 36px;
  margin: 0;
}
.event .event-img {
  border-radius: 10px;
  height: 160px;
  width: 295px;
  object-fit: cover;
}
.commen-problems .badge:hover {
  background-color: $secondary-100 !important;
}
.medical-dpt {
  display: inline-flex;
  align-items: center;
  width: 190px;
  height: 66px;
  margin: 0 20px 16px 0;
  border-radius: 10px;
  cursor: pointer;
  padding-left: 8px;
}
.medical-dpt:hover {
  background-color: $primary-50;
}
.medical-dpt.active {
  background-color: $primary-800;
  color: #fff;
}
.medical-dpt .meical-img {
  width: 50px;
  height: 50px;
  padding: 0;
}
.medical-dpt .meical-img img {
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.dropdown-toggle::after {
  position: absolute;
  background-image: url(../images/arrow-down-sm-gold.svg);
  background-size: 13px;
  width: 13px;
  height: 8px;
  border: none;
  right: 16px;
  top: 19px;
  transition: transform 0.2s ease-in-out;
}
.dropdown-toggle.caret-primary::after {
  background-image: url(../images/arrow-down-sm-blue.svg);
}
.dropdown.show .dropdown-toggle::after {
  transform: rotate(-180deg);
}
.custom-dropdown.dropdown.-error {
  .btn {
    border-color: $danger;
  }
}
.custom-dropdown.dropdown {
  .text-inline {
    gap: 8px;
  }
  .dropdown-toggle.select::after {
    background-image: url(../images/arrow-down-sm-white.svg);
  }
  .dropdown-toggle, .dropdown-toggle:active {
    background-color: #fff;
    color: $primary-900;
    border: solid 1px $primary-100;
    border-radius: 10px;
    font-family: 'Noto Sans Thai', sans-serif;
    font-style: bold;
    padding: 9px 16px;
    width: 100%;
    text-align: left;
  }
  .dropdown-toggle:focus, .dropdown-toggle:active:focus {
    -webkit-transition: box-shadow ease-in-out .15s;
    transition: box-shadow ease-in-out .15s;
    -webkit-box-shadow: 0 0 0 0.2rem rgb(196 155 85 / 25%) !important;
    box-shadow: 0 0 0 0.2rem rgb(196 155 85 / 25%) !important;
  }
  .dropdown-toggle:focus-visible {
    outline: none;
  }
  .dropdown-toggle {
    span {
      padding-right: 40px;
    }
    span.select {
      border-radius: 20px;
      background: $secondary-200;
      color: #fff;
      padding: 0px 26px 0 8px;
      margin-right: -8px;
    }
  }
  .dropdown-item {
    padding: 10px 1rem;
    color: $primary;
  }
  .dropdown-item:hover, .dropdown-item:active {
    background-color: $primary-50;
    color: $primary-900;
  }
  .dropdown-item:focus-visible {
    outline: none;
  }
  .dropdown-menu.show {
    border: solid 1px $primary-200;
    border-radius: 15px;
    padding: 10px 0;
    top: 5px !important;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    height: fit-content;
  }
  .dropdown-menu::-webkit-scrollbar {
    width: 5px;
    background-color: $bg-surface;
    border-radius: 3px;
  }
  .dropdown-menu::-webkit-scrollbar-thumb {
    background: $primary-400;
    border-radius: 3px;
  }
  .dropdown-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $bg-surface;
    border-radius: 3px;
  }
  .dropdown-menu.full.show {
    max-height: max-content;
  }
  .dropdown-menu {
    .form-label {
      margin-bottom: 0;
    }
  }
}
.custom-select {
  .custom-select__control {
    min-height: 44px;
    border: solid 1px $primary-100;
    border-radius: 10px;
  }
  .custom-select__menu {
    z-index: 3;
  }
}
ol.wizard {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  counter-reset: wizard;
  list-style: none;
  padding: 40px 0;
}
ol.wizard li {
  position: relative;
  float: left;
  width: 25%;
  text-align: center;
  color: $secondary-200;
}
ol.wizard .current ~ li {
  color: $light-gray;
}
ol.wizard li:before {
  counter-increment: wizard;
  content: counter(wizard);
  display: block;
  background-color: $secondary-200;
  color: #fff;
  border: 2px solid $secondary-200;
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 55px;
  border-radius: 50%;
  position: relative;
  left: 50%;
  margin-bottom: 10px;
  margin-left: calc(60px * -0.5);
  z-index: 1;
  font-size: 36px;
  font-family: 'Noto Sans Thai', sans-serif;
  font-style: bold;
}
ol.wizard li.current::before {
  color: $secondary-200;
  background-color: #fff;
}
ol.wizard .current ~ li:before {
  color: $light-gray;
  border-color: $light-gray;
  background-color: #fff;
}
ol.wizard li + li {
  &:after {
    content: "";
    display: block;
    border: solid 2px $secondary-200;
    width: 100%;
    position: absolute;
    left: -50%;
    top: 30px;
    z-index: 0;
  }
}
ol.wizard li + li.current::after {
  border-style: dashed;
}
ol.wizard .current ~ li:after {
  border-color: $light-gray;
}
.line-group-time {
  border-bottom: solid 4px $secondary;
}
.block-schedules-period {
  .schedules-period {
    .period-time {
      .btn {
        border: 1px solid $primary;
        border-radius: 20px;
        padding: 8px 10px;
      }
      .btn:hover {
        border: 2px solid $primary !important;
        padding: 7px 9px;
      }
      .btn.active {
        background-color: $primary;
        color: #FFF;
      }
    }
  }
}
.consult .block-group-time {
  height: 310px;
  margin-top: 1rem;
}
.consult .block-time {
  border-radius: 20px;
  border: solid 1px $primary-900;
  padding: 6px;
  text-align: center;
  margin-bottom: 0.75rem;
  cursor: pointer;
}
.consult .block-time.active {
  cursor: default;
  background-color: $secondary-200;
  border-color: $secondary-300;
  color: #fff;
}
.consult .check-have, .setting .check-have {
  padding-left: 110px;
}
.account .check-have {
  padding-left: 60px;
}
.block-attachments {
  display: flex;
}
.block-attachments.multi-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  flex-wrap: wrap;
  div.add {
    width: auto;
    p {
      padding: 0 8px;
    }
  }
}
.block-attachments div, .block-attachments a {
  position: relative;
  border: solid 1px $secondary;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  text-align: center;
}
.block-attachments div.s-52 {
  width: 52px;
  height: 52px;
  border-radius: 10px;
  margin: 0 5px 5px 0;
}
.block-attachments div img, .block-attachments a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.block-attachments div.s-52 img {
  border-radius: 10px;
}
.block-attachments div.add {
  border: solid 1px $secondary;
  text-align: center;
  cursor: pointer;
}
.block-attachments div.add p {
  margin: 8px 0;
  cursor: pointer;
  span.label {
    margin-right: 8px;
  }
  span.label.selected {
    font-weight: 600;
  }
}
.label-profile-image {
  margin-top: 16px;
  text-align: center;
}
.block-attachments div.add input {
  position: absolute;
  outline: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.block-attachments div .remove {
  color: $secondary;
  cursor: pointer;
}
.document-image {
  width: 100%;
  max-height: 240px;
  display: flex;
  justify-content: center;
  img {
    object-fit: contain;
    height: 240px;
  }
}
.consult .payment .card-title, .payment h5.card-title {
  background-color: $primary-50;
  border-radius: 10px 10px 0 0;
  padding: 1rem;
  margin: -1rem -1rem 1rem;
}
.consult .choose-insurance {
  border-radius: 10px;
  border: solid 1px $secondary-200;
  width: 100%;
  padding: 23px 16px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}
.block-payment {
  border-radius: 10px;
  border: solid 1px $secondary-200;
  height: 68px;
  margin-bottom: 1rem;
}
.block-payment .form-label {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 8px;
}
.block-payment .payment-icon {
  width: 70px;
}
.block-payment .checkmark-radio {
  position: inherit;
}
.block-payment .form-check-label .radio-text {
  padding: 0 0 0 20px;
}
.consult .summary {
  background-color: $primary-50;
  padding: 1rem;
  margin: 0 -1rem;
}
.invoice-table {
  margin-bottom: 0;
}
.invoice-table td {
  border: none;
  padding: 0.2rem 0;
}
.account-close .dropdown-menu {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: 16px;
  border-radius: 10px;
  border: none;
  padding: 0;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}
.account-close .dropdown-menu button {
  padding: 1rem;
}
.account-close .dropdown-menu button:hover {
  border-radius: 10px;
}
.block-img-profile {
  width: 80px;
  height: 80px;
  margin: 20px auto 0;
  .icon-profile {
    margin-top: 20px;
  }
  >.btn {
    width: 100%;
    height: 100%;
    padding: unset !important;
  }
}
.block-img-profile .icon-plus {
  position: absolute;
  right: 5px;
  bottom: 0;
}
.payment .block-status {
  margin: 115px 0 1.5rem;
  padding: 40px;
  text-align: center;
}
.payment .block-status svg {
  margin: 0 auto;
  margin-bottom: 25px;
}
.payment.container {
  max-width: 1030px;
}
body.coupon-show {
  overflow: hidden;
}
.overlay-coupon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 9999;
  display: none;
}
.overlay-coupon.open {
  display: block;
  overflow: hidden;
}
.history .nav .nav-item {
  padding: 8px 0;
}
.history .nav .nav-item .nav-link {
  font-family: 'Noto Sans Thai', sans-serif;
  font-style: bold;
  color: $light-gray;
  border-radius: 0;
  padding: 8px;
}
.history .nav .nav-item .nav-link:hover {
  background-color: transparent;
  color: $primary-900;
}
.history .nav .nav-item:first-child .nav-link {
  border-right: solid 2px $line-gray;
}
.history .nav .nav-item .nav-link.active {
  background-color: transparent;
  border-radius: 0 !important;
}
.history .card.list {
  border: 1px solid $line-gray;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.history .card.list table {
  margin: 0;
}
.history .card.list table td {
  border: none;
  padding: 0;
}
.line-status-consult {
  display: flex;
  justify-content: center;
  color: $secondary-200;
  padding: 1.5rem 0;
}
.line-status-consult .line {
  border-bottom: 4px solid $secondary-200;
  width: 120px;
  height: 0;
  margin: 0px 20px;
  align-self: center;
}
.line-status-consult div.active {
  border-bottom-style: dashed;
}
.line-status-consult div.active ~ div {
  color: $light-gray;
}
.line-status-consult div.active ~ div.line {
  border-bottom: 4px dashed $light-gray;
}
.line-status-consult.cancel {
  color: $danger;
}
.line-status-consult.cancel .line {
  border-bottom: 4px dashed $danger;
}
.follow-case {
  background-color: $secondary-100;
  margin: 1rem -1rem -1rem;
  padding: 1rem;
  border-radius: 0 0 10px 10px;
  text-align: center;
}
.alert-appointment {
  background-color: $secondary-100;
  padding: 1rem;
}
.cookie-permission, .coupon-check {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.15);
  padding: 1.5rem;
  z-index: 9990;
}
.cookie-permission .close {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  right: 1rem;
  align-items: center;
  cursor: pointer;
}
.accordion-button, .accordion-body {
  padding: 1rem;
}
.accordion-button:focus {
  box-shadow: none;
}
.accordion-button::after {
  background-image: url(../images/arrow-down.svg);
  background-size: 100%;
  margin: auto 0 auto auto;
  width: 17px;
  height: 17px;
}
.accordion-button:not(.collapsed)::after {
  background-image: url(../images/arrow-down.svg);
}

@media (max-width: $screen-lg) {
  .navbar {
    margin-top: 0;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
    .container-fluid {
      padding: 0 20px;
      height: 68px;
    }
  }
  .navbar-button .nav-item.sign-in {
    margin: 0;
  }
  .navbar-toggler {
    padding: 4px 0;
    text-align: left;
    border: unset;
  }
  .navbar .logo {
    height: 44px;
    margin: 4px 0;
  }
  .navbar-button .nav-item .btn {
    min-width: 80px;
    font-size: 12px;
    padding: 6px 0.75rem;
    border: solid 2px;
  }
  section.main {
    margin-top: 68px;
  }
  section.download .description {
    font-size: 16px;
  }
  .img-service {
    height: 120px;
  }
  .img-service img {
    height: 130px;
  }
  .home-title {
    font-size: 28px;
  }
  .block-home {
    padding: 100px 0;
  }
  .block-home .service h3 {
    font-size: 19px;
  }
  .block-home .service p {
    font-size: 12px;
    line-height: 16px;
  }
  .block-home .medical-services-title {
    font-size: 32px;
  }
  .block-home .medical-services-desc {
    font-size: 16px;
    max-width: 550px;
    margin: 0.75em auto 65px;
    line-height: 24px;
  }
  .block-banner {
    .login-footer {
      ._label-text {
        margin-top: 9px;
        margin-bottom: 8px;
        font-size: 20px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 820px) {
  .login-with {
    span {
      margin: 0 4px;
    }
    button {
      padding: 0 4px;
    }
    button:focus {
      padding: 0 4px;
    }
  }
}

@media (max-width: $screen-md) {
  .block-home .service h3 {
    font-size: 17px;
  }
  .navbar {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
}

@media (max-width: $screen-sm) {
  .block-banner {
    .login-footer {
      ._label-text {
        margin-bottom: 8px;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .navbar {
    .container-fluid {
      padding: 0 16px;
      height: 60px;
    }
    .navbar-toggler {
      padding: 0;
      text-align: left;
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
  .font-12 {
    font-size: $size-10 !important;
  }
  .font-14 {
    font-size: $size-12 !important;
  }
  .font-16 {
    font-size: $size-14 !important;
  }
  .font-20 {
    font-size: $size-18 !important;
  }
  .font-22 {
    font-size: $size-20 !important;
  }
  .font-24 {
    font-size: $size-22 !important;
  }
  .font-26 {
    font-size: $size-24 !important;
  }
  .font-28 {
    font-size: $size-26 !important;
  }
  .font-30 {
    font-size: $size-28 !important;
  }
  .font-32 {
    font-size: $size-30 !important;
  }
  .font-34 {
    font-size: $size-32 !important;
  }
  .font-36 {
    font-size: $size-34 !important;
  }
}

@media (max-width: 428px) {
  .navbar .logo {
    height: 36px;
  }
  section.main {
    margin-top: 60px;
  }
  .navbar-button .nav-item.sign-in .btn {
    padding: 3px 10px;
    background-color: $secondary-200;
    border: solid 5px $secondary-200;
    color: #fff;
  }
  .modal-open .navbar {
    padding-right: 0;
  }
  section.download {
    padding: 16px 0;
  }
  section.download .description {
    font-size: 14px;
  }
  section.download a {
    min-width: auto;
    width: 100%;
  }
  .block-home {
    padding: 60px 16px 100px;
  }
  .block-home .medical-services-title {
    font-size: 24px;
  }
  .block-home .medical-services-desc {
    margin: 0.5em auto 60px;
  }
  .block-home .service, .block-home .fix-row-sm {
    margin: 0;
  }
  .home-title {
    padding: 0 1rem;
  }
  .carousel-home .carousel-caption {
    left: 16px;
    top: 25px;
  }
  .carousel-home .carousel-caption h5 {
    font-size: 36px;
    margin-bottom: 1rem;
  }
  .carousel-home .carousel-caption p {
    font-size: 12px;
    max-width: 252px;
    line-height: 18px;
    margin-bottom: 1rem;
  }
  .carousel-home .carousel-caption .btn {
    min-width: 200px;
    font-size: 12px;
    height: auto;
    padding: 4px 0.75rem;
    bottom: 0;
  }
  .carousel-indicators {
    margin-bottom: 0;
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    width: 1rem;
    height: 1rem;
  }
  .block-form {
    width: 100%;
    min-height: auto;
    flex: auto;
  }
  .register .block-form {
    min-height: 500px;
  }
  .block-banner {
    padding: 20px 16px;
  }
  .block-banner .action-login {
    padding: 0;
  }
  .block-banner .action-login h3 {
    font-size: 16px;
    margin-bottom: 6px !important;
  }
  .block-banner .action-login p {
    font-size: 12px;
    margin-bottom: 9px !important;
    line-height: 15.6px;
  }
  .login-with {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 5px;
  }
  .login-with span {
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0;
    img {
      width: 40px;
    }
  }
  .navbar-button .nav-item {
    margin-left: 7px;
  }
  .navbar-button .nav-item .btn.icon {
    width: 32px;
    height: 32px;
  }
  .navbar-button .nav-item .btn.icon.account {
    padding: 0;
  }
  .navbar-button .nav-item .btn.icon.account .account-icon {
    padding: 5px;
  }
  .navbar-button .nav-item .btn.icon.account:hover .account-icon {
    border: solid 1px $secondary-200;
    border-radius: 50%;
    padding: 4px;
  }
  .navbar-button .nav-item .btn.icon:hover {
    color: $primary-900;
  }
  .navbar-button .nav-item.dropdown .dropdown-menu, .consult .timezone .dropdown-menu {
    position: fixed !important;
    top: 60px !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
    width: 396px;
  }
  .Toastify__toast-container {
    width: 96%;
    margin: 0 auto;
    top: 1rem;
    right: 0;
    left: 0;
  }
  .font-sm-12 {
    font-size: 12px;
  }
  .font-sm-32 {
    font-size: 32px;
  }
}

@media (max-width: 390px) {
  .navbar-button .nav-item.dropdown .dropdown-menu, .consult .timezone .dropdown-menu {
    width: 358px;
  }
  .block-form .otp input, .otp input {
    width: 42px !important;
    height: 45px;
  }
}

@media (max-width: $screen-xs) {
  .navbar-button .nav-item.dropdown .dropdown-menu, .consult .timezone .dropdown-menu {
    width: 343px;
  }
  .font-12 {
    font-size: $size-10;
  }
  .font-14 {
    font-size: $size-12;
  }
  .font-16 {
    font-size: $size-14;
  }
  .font-20 {
    font-size: $size-18;
  }
  .font-22 {
    font-size: $size-20;
  }
  .font-24 {
    font-size: $size-22;
  }
  .font-26 {
    font-size: $size-24;
  }
  .font-28 {
    font-size: $size-26;
  }
  .font-30 {
    font-size: $size-28;
  }
  .font-32 {
    font-size: $size-30;
  }
  .font-34 {
    font-size: $size-32;
  }
  .font-36 {
    font-size: $size-34;
  }
}

@media (max-width: 360px) {
  .navbar-button .nav-item.dropdown .dropdown-menu, .consult .timezone .dropdown-menu {
    width: 328px;
  }
  .block-form .otp input, .otp input {
    width: 40px !important;
    height: 45px;
  }
}

.canvas-chat {
  border-radius: 20px 0px 0px 20px;
}
.canvas-chat .offcanvas-header {
  display: block;
  background: linear-gradient(209.83deg, $primary-900 7.82%, $primary-400 158.8%);
  border-radius: 20px 0px 0px 0;
  text-align: center;
  padding: 11px 1rem;
}
.canvas-chat .offcanvas-title {
  font-size: 20px;
  color: #fff;
}
.canvas-chat .offcanvas-body {
  padding: 0;
  overflow: hidden;
}
.canvas-chat .list-chat {
  height: calc(100% - 65px);
  padding: 1rem 24px;
  overflow-y: auto;
}
.list-chat {
  height: calc(75vh - 140px);
  padding: 1rem 24px;
  overflow-y: auto;
}
.list-chat::-webkit-scrollbar {
  width: 0px;
}
.list-table {
  display: table;
  width: 100%;
  height: 100%;
}
.list-cell {
  display: table-cell;
  vertical-align: bottom;
  .message {
    margin-top: 8px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    .msg-text {
      display: flex;
      margin: 3px 1rem 0 0;
      background-color: #FFF;
      border-radius: 0px 24px 24px 24px;
      span {
        font-size: $size-12;
        padding: 8px 16px;
        border-radius: 0px 24px 24px;
        word-break: break-all;
      }
      span.file {
        max-width: 140px;
        padding: 4px;
        img {
          cursor: pointer;
          object-fit: cover;
          width: 100%;
          max-height: 180px;
          border-radius: 4px;
        }
      }
    }
    .msg-time {
      font-family: 'Noto Sans Thai', sans-serif;
      font-style: bold;
      font-size: $size-10;
      color: $light-gray;
      line-height: $size-16;
    }
  }
  .message.mine {
    align-items: flex-end;
    .msg-text {
      margin: 3px 0 0 1rem;
      border-radius: 24px 0px 24px 24px;
      span {
        border-radius: 24px 0px 24px 24px;
      }
      svg {
        cursor: pointer;
        align-self: center;
        margin-right: 6px;
        min-width: 20px;
        color: $light-gray;
      }
    }
    .row {
      flex-direction: row-reverse;
    }
    .msg-time {
      text-align: right;
    }
  }
}
.canvas-chat .list-chat::-webkit-scrollbar, .canvas-address .offcanvas-body::-webkit-scrollbar {
  width: 0px;
}
.canvas-chat .list-table {
  display: table;
  width: 100%;
  height: 100%;
}
.canvas-chat .list-cell {
  display: table-cell;
  vertical-align: bottom;
}
.canvas-chat .list-cell .message {
  margin-top: 8px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}
.canvas-chat .list-cell .message.mine {
  align-items: flex-end;
}
.canvas-chat .list-cell .message.mine .row {
  flex-direction: row-reverse;
}
.canvas-chat .list-cell .message .msg-text {
  display: flex;
  margin: 3px 1rem 0 0;
}
.canvas-chat .list-cell .message.mine .msg-text {
  margin: 3px 0 0 1rem;
}
.canvas-chat .list-cell .message .msg-text span {
  font-size: 12px;
  background-color: $surface;
  color: #000;
  padding: 8px 16px;
  border-radius: 0px 24px 24px;
  word-break: break-all;
}
.canvas-chat .list-cell .message .msg-text span.file {
  max-width: 140px;
  padding: 4px;
}
.canvas-chat .list-cell .message .msg-text span.file img {
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  max-height: 180px;
  border-radius: 4px;
}
.canvas-chat .list-cell .message.mine .msg-text span {
  border-radius: 24px 0px 24px 24px;
}
.canvas-chat .list-cell .message.mine .msg-text svg {
  cursor: pointer;
  align-self: center;
  margin-right: 6px;
  min-width: 20px;
  color: $light-gray;
}
.canvas-chat .list-cell .message .msg-time {
  font-size: 10px;
  color: $light-gray;
  line-height: 16px;
}
.canvas-chat .list-cell .message.mine .msg-time {
  text-align: right;
}
.canvas-chat .block-chat {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 14px 24px;
  border-top: 1px solid $line-gray;
}
.canvas-chat .block-chat input {
  background-color: $surface;
  border: none;
  border-radius: 18px;
  padding: 9px;
  font-size: 12px;
}
.canvas-chat .chat-attacch {
  position: relative;
  cursor: pointer;
}
.canvas-chat .chat-attacch input {
  position: absolute;
  outline: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.block-img-profile.chat {
  margin: 0;
  padding: 0;
  width: 36px;
  height: 36px;
}
.block-chat, .block-message {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  .block-patient {
    .block-img-profile .icon-profile {
      margin-top: 0;
    }
    .block-detail-profile {
      .message {
        margin: auto;
        font-family: 'Noto Sans Thai', sans-serif;
        font-weight: 300;
        font-size: $size-12;
        line-height: $size-16;
      }
    }
  }
  .chat-box-list {
    padding: 16px 20px;
    border-bottom: 1px solid $line-gray;
  }
  .chat-box-list.active, .chat-box-list:hover {
    background-color: $primary-50;
  }
  .chat-box-list.active {
    .block-patient {
      .block-img-profile {
        background-color: $surface;
      }
      .block-detail-profile {
        .message {
          margin: auto;
          font-family: 'Noto Sans Thai', sans-serif;
          font-style: bold;
          font-weight: 500;
          font-size: $size-12;
          line-height: $size-16;
        }
      }
    }
  }
  .chat-box-list.active:hover {
    .block-patient {
      .block-img-profile {
        background-color: $primary-50;
      }
      .block-detail-profile {
        .message {
          margin: auto;
          font-family: 'Noto Sans Thai', sans-serif;
          font-style: bold;
          font-weight: 500;
          font-size: $size-12;
          line-height: $size-16;
        }
      }
    }
  }
  .chat-box-list.active:hover {
    background-color: $surface;
  }
  .chat-unread {
    background-color: $danger;
    padding: 2px 7.5px;
    border-radius: 10px;
    color: #FFF;
  }
  .chat-attacch {
    position: relative;
    input {
      position: absolute;
      outline: none;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
}
.block-message {
  display: flex;
  flex-direction: row;
  gap: 20px;
  .block-item {
    width: 100%;
    .block-data {
      padding: 10px 16px;
      .block-patient {
        .block-img-profile {
          width: 40px;
          height: 40px;
          svg {
            height: 24px;
          }
        }
        .block-detail-profile {
          .detail > .detail-item > .code {
            font-family: 'Noto Sans Thai', sans-serif;
            font-style: bold;
            font-size: $size-12;
            font-weight: 500;
            line-height: $size-16;
            color: $light-gray;
          }
        }
      }
      .text-inline {
        min-height: 40px;
      }
      .title-diagnosis {
        position: absolute;
        margin-top: 100px;
        margin-left: -37px;
        display: flex;
        align-items: center;
        transform: rotate(90deg);
        width: max-content;
      }
    }
    .message-data {
      .block-chat-sent {
        padding: 10px 16px;
        background-color: #FFF;
        border-radius: 10px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  .block-item:not(:first-child):last-child {
    width: auto;
    .block-data {
      padding-left: 6px;
      padding-right: 6px;
      height: 100%;
      width: min-content;
      .text-inline {
        flex-direction: column;
      }
    }
  }
  .block-item.active {
    width: 100% !important;
    .block-data {
      width: 100% !important;
      max-height: 60px;
      .text-inline {
        flex-direction: row !important;
        .btn-icon > svg {
          transform: rotate(180deg);
        }
      }
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .title-diagnosis {
      position: relative;
      margin: auto 0;
      transform: unset;
      width: auto;
    }
  }
}
.chat-image {
  width: 36px !important;
  height: 36px !important;
}
.loading-chating {
  color: #1e2125;
  text-align: center;
  font-size: 13px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.title > span {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.card .title > span {
  right: 1rem;
}
.title > span.view-more {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: 12px;
  color: $secondary-200;
  cursor: pointer;
}
.card-list {
  border: solid 1px $line-gray;
  margin: 0;
}
.card-list .card-title {
  background-color: $primary-50;
  padding: 8px 1rem;
  border-radius: 10px 10px 0 0;
  margin: 0;
}
.card-list .card-title > table, .card-list .card-body > table {
  margin: 0;
}
.card-list .card-title > table th {
  padding: 0 0.5rem;
  border: none;
}
.card-list .card-body {
  padding: 1rem 1rem 0;
}
.card-list .card-body > table {
  padding: 1px;
  border-radius: 10px;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0 1rem;
  margin-top: -1rem;
}
.card-list .card-body > table td {
  background-color: $surface;
  border: none;
  padding: 8px 10px;
}
.card-list .card-body > table td:first-child {
  border-radius: 10px 0 0 10px;
}
.card-list .card-body > table td:last-child {
  border-radius: 0 10px 10px 0;
}
.card-list .card-body tbody tr.empty td {
  background-color: #fff;
  text-align: center;
  color: $light-gray;
}
.block-pateint-last {
  background-color: $surface;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 1rem 8px;
}
.block-pateint-last:last-child {
  margin: 0;
}
.block-pateint-last button.none, .block-pateint-last button.none:focus {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  font-size: 12px;
  text-align: left;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}
.block-pateint-last button.none:after {
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1rem;
  transition: transform .2s ease-in-out;
}
.block-pateint-last.active button.none:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.last-booking-card {
  background-color: $surface;
  min-height: 208px;
  margin: 0;
  padding: 8px;
}
.block-dash-wallet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 26px 0 32px;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-400;
    color: #FFFFFF;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  .balance {
    font-family: 'Noto Sans Thai', sans-serif;
    font-style: bold;
    font-size: 48px;
    margin: 2rem 0 1rem;
    color: $secondary-200;
  }
}
.doughnut {
  position: relative;
  margin: auto;
  margin-right: 2rem;
  height: 200px;
  width: 200px;
}
.donut-inner {
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.donut-inner h5 {
  font-family: 'Noto Sans Thai', sans-serif;
  font-style: bold;
  font-size: 40px;
  color: $secondary-200;
}
.doughnut-status {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
}
.doughnut-status > div {
  display: flex;
}
.doughnut-status > div::before {
  content: " ";
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
}
.doughnut-status > div.complete::before {
  background-color: $success;
}
.doughnut-status > div.waiting::before {
  background-color: $warning;
}
.doughnut-status > div.cancel::before {
  background-color: $danger;
}
.doughnut-status > div > div {
  margin-top: -2px;
}
.case-static-filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.case-static-filter div {
  font-size: 12px;
  margin-left: 40px;
}
.case-static-filter div:first-child {
  margin: 0;
}
.case-static-filter select {
  margin-left: 8px;
  padding: 8px;
  border-radius: 10px;
  padding-right: 36px;
  border: unset;
  appearance: none;
  background: $surface url(../images//arrow-down.png) no-repeat center right 8px;
  background-size: 14px;
  color: $primary-900;
}
.btn-w-200 {
  min-width: 200px;
}

@media (max-width: 991px) {
  .say-hi {
    font-size: 28px;
    h3 {
      color: $secondary-200;
      span {
        color: $primary-900;
        margin-left: 1rem;
      }
    }
  }
  .case-static-filter {
    justify-content: flex-start;
    margin-bottom: 2rem;
  }
  .doughnut {
    margin: 0;
    width: 170px;
    height: 170px;
  }
}

@media (max-width: 430px) {
  .btn-w-200 {
    width: 100%;
    min-width: auto;
  }
  .btn-w-200 {
    font-size: 12px;
  }
  .block-register-doctor {
    justify-content: flex-end;
    padding: 4px 2px;
    .icon-join-us {
      bottom: -30px;
    }
    .logo-icon-white {
      top: -30px;
    }
    > div:first-child {
      position: absolute;
      left: 0;
    }
    .detail {
      max-width: max-content;
      z-index: 1;
      .btn {
        max-width: 180px;
      }
    }
  }
}
.network-signal {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 5000;
  font-size: 12px;
  width: 300px;
  text-align: center;
  margin-left: -150px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #e9ecef;
  padding: 10px;
}
.share-screen {
  border-radius: 10px;
  padding: 4px;
}
.share-screen svg, .icon-chat {
  color: $secondary-200;
  cursor: pointer;
  width: 32px;
  height: 32px;
}
.share-screen.on {
  background-color: $secondary-200;
}
.share-screen.on svg {
  color: #fff;
}
.hidden-record-action {
  position: fixed;
  top: 1px;
  left: -120px;
  @media (max-width: $screen-sm) {
    top: 90px;
    left: -120px;
  }
}
.show-room-video {
  position: fixed;
  top: 10px;
  left: 10px;
  @media (max-width: $screen-sm) {
    top: 100px;
    left: 10px;
  }
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #a7a7a7;
  border-radius: 5px;
  font-size: 12px;
  opacity: 0.5;
  z-index: 1;
}
.message-notify {
  position: relative;
  top: -45px !important;
  left: 30px !important;
  border: 1px solid red;
}
.text-alert-video {
  color: #FF2828;
}
.button-fixed-bottom {
  position: fixed;
  width: 50px;
  height: 50px;
  padding: 8px;
  border: 1px solid #ff7556;
  background-color: #ffffff;
  box-shadow: 0 0 10px #0000001a;
  bottom: 3%;
  right: 10px;
  border-radius: 50%;
  color: #ff7556;
  z-index: 1000;
}
.list-step {
  position: absolute;
  background-color: #D2B687;
  border-radius: 20px;
  height: 28px;
  width: 28px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  padding-top: 2px;
}
.list-step-label {
  position: absolute;
  margin-left: 40px;
}
.kyc-step {
  min-height: 50px;
}
.block-img-idcard {
  width: 300px !important;
  border-radius: 0 !important;
  height: 150px !important;
}
.icon-plus-fixed {
  margin-bottom: 5px !important;
  margin-right: 5px !important;
}
.block-img-idcard img {
  border-radius: 0 !important;
}
.time-consult {
  border-radius: 20px;
  padding: 4px 8px;
  color: #FFFFFF;
  font-weight: 300;
  font-size: 12px;
  width: max-content;
  background-color: #ffc43f;
}
.prepare-video {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $primary-700;
  z-index: 1100;
  top: 0;
  left: 0;
}
.prepare-image {
  position: relative;
  background-color: #ffffff;
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: 100px;
  border-radius: 50%;
  text-align: center;
}
.prepare-name {
  position: relative;
  margin: auto;
  text-align: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
}
.prepare-text-info {
  position: relative;
  margin: auto;
  text-align: center;
  color: #ffffff;
  margin-top: 100px;
}
.prepare-text-auto {
  position: relative;
  margin: auto;
  text-align: center;
  font-size: 12px;
  color: #dddddd;
  margin-top: 10px;
}
.prepare-action {
  position: relative;
  width: 220px;
  margin: auto;
  text-align: center;
  margin-top: 20px;
}
.prepare-avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.text-none {
  display: none;
}
.prepare-text-time {
  position: relative;
  margin: auto;
  text-align: center;
  color: #ffffff;
}
.prepare-avatar-icon {
  width: 100px;
  height: 100px;
  margin-top: 15px;
}
.close-link {
  margin-top: 20px;
}
.close-link .btn-outline-primary {
  color: #dddddd;
}
.change-booking-newtime {
  border: 1px solid #FFC012;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  color: #FFC012;
}
.video-text-information {
  position: relative;
  border: 1px solid #a7a7a7;
  border-radius: 20px;
  margin-top: -100px;
  padding: 10px;
  text-align: center;
  background: $dark-gray;
  color: #FFF;
  @media (max-width: $screen-sm) {
    margin-top: -130px;
  }
  @media (max-width: $screen-xs) {
    margin-top: -150px;
  }
}
.video-text-information .end-call {
  background: #FF2828;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  width: 40px;
  height: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  svg {
    transform: rotate(135deg);
    height: 16px;
    width: 16px;
  }
}
.not-drug-enabled {
  text-align: center;
  padding: 20px;
}
.showalertcase {
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #FF2828;
  margin-left: 10px;
  margin-top: 10px;
  z-index: 1350;
  padding: 10px;
  color: #292929;
}
.table-custom > tbody > tr:nth-of-type(2n+2) > * {
  background-color: $bg-surface;
}
.table-custom > thead > tr > * {
  font-family: 'Noto Sans Thai', sans-serif;
  font-style: bold;
  font-weight: 600;
  background-color: $bg-surface;
  text-align: center;
}
.table-custom > thead > tr > *:not(:first-child) {
  border-left: 1px solid $dark-gray;
}
.table-custom {
  tr {
    th {
      color: $primary;
    }
    th, td {
      margin: auto 0;
      vertical-align: middle;
    }
  }
  @media (max-width: $screen-md) {
    > :not(caption) > * > * {
      padding: 0.25rem 0.25rem;
    }
  }
}
.table-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  .table-div {
    border: 1px solid $primary-100;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .table-data {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > :last-child {
        margin-left: auto;
        display: flex;
      }
      @media (max-width: $screen-xs) {
        align-items: start;
        flex-direction: column;
      }
    }
  }
}
.pagination {
  color: $primary;
  .page-item {
    .page-link {
      border-color: $primary-100;
      color: $primary;
    }
    .page-link:focus {
      box-shadow: none;
    }
    .page-link:hover {
      background-color: $primary-200;
    }
  }
  .page-item.active {
    .page-link {
      border-color: $primary-600;
      color: $color-white;
      background-color: $primary;
    }
  }
}
.filter-doctor {
  margin-bottom: 1rem;
  text-align: right;
}
.dropdown-toggle::after {
  position: absolute;
  background-image: url(../images/arrow-down-sm-gold.svg);
  background-size: 13px;
  width: 13px;
  height: 8px;
  border: none;
  right: 16px;
  top: 19px;
  transition: transform 0.2s ease-in-out;
}
.dropdown-toggle.caret-primary::after {
  background-image: url(../images/arrow-down-sm-blue.svg);
}
.dropdown.show .dropdown-toggle::after {
  transform: rotate(-180deg);
}
.filter-doctor .dropdown, .block-banner .dropdown {
  display: inline-flex;
}
.filter-doctor .dropdown-toggle.select::after, .block-banner .dropdown-toggle.select::after {
  background-image: url(../images/arrow-down-sm-white.svg);
}
.filter-doctor .dropdown-toggle, .filter-doctor .dropdown-toggle:active, .block-banner .dropdown-toggle, .block-banner .dropdown-toggle:active {
  background-color: #fff;
  color: $primary-900;
  border: solid 1px $primary-100;
  border-radius: 10px;
  font-family: 'Noto Sans Thai', sans-serif;
  font-style: bold;
  padding: 10px 16px;
  min-width: 180px;
  text-align: left;
}
.filter-doctor .dropdown-toggle:focus, .filter-doctor .dropdown-toggle:active:focus, .block-banner .dropdown-toggle:focus, .block-banner .dropdown-toggle:active:focus {
  -webkit-transition: box-shadow ease-in-out .15s;
  transition: box-shadow ease-in-out .15s;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(196 155 85 / 25%) !important;
  box-shadow: 0 0 0 0.2rem rgb(196 155 85 / 25%) !important;
}
.filter-doctor .dropdown-toggle:focus-visible, .block-banner .dropdown-toggle:focus-visible {
  outline: none;
}
.filter-doctor .dropdown-toggle span, .block-banner .dropdown-toggle span {
  padding-right: 40px;
}
.filter-doctor .dropdown-toggle span.select, .block-banner .dropdown-toggle span.select {
  border-radius: 20px;
  background: $secondary-200;
  color: #fff;
  padding: 0px 26px 0 8px;
  margin-right: -8px;
}
.filter-doctor .dropdown-item, .block-banner .dropdown-item {
  padding: 10px 1rem;
}
.filter-doctor .dropdown-item:hover, .filter-doctor .dropdown-item:active, .block-banner .dropdown-item:hover, .block-banner .dropdown-item:active {
  background-color: $primary-50;
  border-radius: 10px;
  color: $primary-900;
}
.filter-doctor .dropdown-item:focus-visible, .block-banner .dropdown-item:focus-visible {
  outline: none;
}
.filter-doctor .dropdown-menu.show, .block-banner .dropdown-menu.show {
  border: solid 1px $primary-200;
  border-radius: 15px;
  padding: 10px 0;
  top: 5px !important;
  min-width: 200px;
  max-height: 300px;
  overflow: auto;
  height: 300px;
}
.filter-doctor .dropdown-menu .form-label, .block-banner .dropdown-menu .form-label {
  margin-bottom: 0;
}
.nhb-detail .menu .dropdown-menu {
	background: $background;
	right: 0 !important;
}
.nhb-detail .menu .dropdown-menu button {
	background: $background !important;
	border: none;
}
.nhb-detail .menu .dropdown-menu button:first-child {
	border-bottom: 1px solid $line-gray;
}
.menu .dropdown-menu button:first-child:hover {
	border-radius: 10px 10px 0 0;
}
.menu .dropdown-menu button:last-child:hover {
	border-radius: 0 0 10px 10px;
}
.nhb-detail .center-logo {
	width: 44px;
	height: 44px;
	min-width: auto;
}
.nhb-detail .google-map {
	height: 100px;
}
.block-nursing-detail .sevices .google-map > div, .nhb-detail .google-map > div {
	border-radius: 10px;
}
.block-nursing-detail .sevices .addr, .nhb-detail .addr {
	display: flex;
	padding: 0px 1rem 0;
	gap: 1rem;
}
.guest .img-guest img, .nh-center .img img, .nhb-detail .center-logo img {
	border-radius: 50%;
}
.nhb-detail {
	margin-bottom: 80px;
}
.nhb-detail .booking-number {
	margin-bottom: 20px;
}
.nhb-detail .booking-number .dropdown > span {
	cursor: pointer;
	color: $primary;
}
.nhb-detail .booking-number .dropdown > span:hover {
	color: $primary-600;
}
.nhb-detail .timeline-status {
	display: flex;
	padding: 12px 16px;
	flex-direction: column;
	gap: 16px;
	border-radius: 16px;
	background: $background;
}
.line-status-consult {
	display: flex;
	justify-content: center;
	color: $secondary;
	padding: 1.5rem 0;
}
.timeline-status .line-status-consult {
	justify-content: space-between;
	padding: 0;
}
.line-status-consult .line {
	border-bottom: 4px solid $secondary;
	width: 120px;
	height: 0;
	margin: 0px 20px;
	align-self: center;
}
.timeline-status .line-status-consult .line {
	border-bottom: 4px dashed $secondary;
	width: 100%;
	margin: 0 1rem;
}
.timeline-status .line-status-consult .icon {
	color: $light-gray;
}
.timeline-status .line-status-consult .icon.active {
	color: $secondary;
	border: none;
}
.timeline-status .line-status-consult div.active ~ div.line {
	border-bottom: 4px dashed $secondary;
}
.timeline-status .line-status-consult.signout div.active ~ div.line {
	border-bottom: 4px solid $secondary;
}
.line-status-consult div.active {
	border-bottom-style: dashed;
}
.line-status-consult div.active ~ div {
	color: $light-gray;
}
.line-status-consult div.active ~ div.line {
	border-bottom: 4px dashed $light-gray;
}
.line-status-consult.submit {
	color: $light-gray;
}
.line-status-consult.submit .line, .line-status-consult.waiting_verification .line {
	border-bottom: 4px dashed $light-gray;
}
.line-status-consult.cancel, .timeline-status .line-status-consult.cancel .icon {
	color: $danger;
}
.line-status-consult.cancel .line {
	border-bottom: 4px dashed $danger;
}
.line-status-consult .icon svg, .line-status-consult .icon img {
	width: 40px;
	height: 40px;
}
.timeline-status .line-status-consult .icon svg {
	width: 28px;
	height: 28px;
}
.follow-case {
	background-color: $secondary-100;
	margin: 1rem -1rem -1rem;
	padding: 1rem;
	border-radius: 0 0 10px 10px;
	text-align: center;
}
.block-patient.normal {
  .block-img-profile {
    width: 60px !important;
    height: 60px !important;
  }
  .block-detail-profile {
    >.title {
      font-size: $size-18 !important;
    }
  }
}
.block-patient.small {
  .block-img-profile {
    width: 40px !important;
    height: 40px !important;
  }
  .block-detail-profile {
    >.title {
      font-size: $size-16 !important;
    }
  }
}
.icon-bed {
  background-color: $primary;
  width: 40px;
  height: 40px;
  color: $color-white;
  border-radius: 50%;
  padding: 5px;
  svg {
    width: 100%;
    height: 100%;
  }
}
.block-img-square {
  max-height: 100px;
  height: auto;
  max-width: 160px;
  width: 160px;
  margin: auto;
  color: $primary;
  background-color: $primary-50;
  border-radius: 10px;
  position: relative;
  input {
    position: absolute;
    outline: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
  .btn {
    padding: 0;
  }
  img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .icon-profile {
    padding: 10px;
    width: 60%;
    height: 60%;
    margin: auto;
    display: flex;
    justify-content: center;
  }
  .icon-plus {
    position: absolute;
    right: 5px;
    bottom: 0;
  }
  .remove {
    position: absolute;
    top: 0;
    right: 0;
    color: $danger;
    cursor: pointer;
  }
  .crop {
    position: absolute;
    top: 10px;
    left: 0;
    color: $primary;
    cursor: pointer;
  }
  .crop-right {
    left: unset;
    right: 0;
  }
}
.block-img-square.mini {
  max-width: 80px;
  max-height: 80px;
}
.textarea-description{
  width: 100%;
  padding: 10px;
  border: 1px solid #dddddd;
  margin-bottom: 10px;
}
.seo-description{
  position: absolute;
  color: #555555;
  font-size: 0.5rem;
  background-color: #ffffff;
  margin-top: -30px;
  width: 190px;
  margin-left: 5px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 0.5;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seo-description-small{
  position: absolute;
  color: #555555;
  font-size: 0.5rem;
  background-color: #ffffff;
  margin-top: -30px;
  width: 70px;
  margin-left: 5px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 0.5;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard.overview {
  .block-data {
    padding: 1rem;
    background-color: $color-white;
    border-radius: 8px;
    border: 1px solid $line-gray;
    .order::-webkit-scrollbar {
      width: 1px;
      height: 1px;
      background-color: $bg-surface;
      border-radius: 10px;
    }
    .order::-webkit-scrollbar-thumb {
      background: $primary-400;
      border-radius: 3px;
    }
    .order::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px $bg-surface;
      border-radius: 3px;
    }
    .order {
      margin-top: 0.5rem;
      display: flex;
      gap: 0.75rem;
      overflow: auto;
      .booking-card {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        min-width: 360px;
        border-radius: 8px;
        background-color: $bg-surface;
        padding: 0.75rem;
        @media (max-width: $screen-sm) {
          min-width: fit-content;
        }
      }
    }
  }
}
progress {
  border-radius: 4px; 
  height: 8px;
  box-shadow: 1px 1px 4px rgba( 0, 0, 0, 0.2 );
}
progress::-webkit-progress-bar {
  background-color: $bg-surface;
  border-radius: 4px;
}
progress::-webkit-progress-value {
  background-color: $primary;
  border-radius: 4px;
}
progress::-moz-progress-bar {
  /* style rules */
}

.block-img-passport{
  position: relative;
  width: 320px;
  height: 170px;
  color: #1D295A;
  background-color: #E7EAF1;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}

.block-img-passport input {
  position: absolute;
  outline: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.block-img-passport img{
  height: 150px;
}

.block-img-passport .icon-passport{
  margin-top: 40px;
}

.block-img-passport .remove{
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  color: #FF2828;
}

.block-complaint {
  .block-header {
    padding: 12px 16px;
    border-radius: 16px;
    background-color: $bg-surface;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
.weekly {
  display: flex;
  justify-content: space-around;
  > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 52px;
    height: 52px;
    border-radius: 30px;
  }
  .btn.active {
    background-color: $primary;
    border-radius: 30px;
    color: $color-white;
  }
}
.continue-rest {
  .add-logo {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 8px 12px;
    gap: 8px;
    color: $secondary;
  }
  .add-logo input {
    position: absolute;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
  .add-logo .is-data {
    position: relative;
    margin: auto;
    img {
      width: 200px;
      height: 200px;
      object-fit: contain;
    }
    .remove {
      position: absolute;
      padding: 0;
      top: -10px;
      right: -10px;
    }
  }
}
.img-payment {
  img {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
}
.commission {
  .table-custom {
    .block-patient {
      .block-detail-profile {
        .title {
          font-family: 'Noto Sans Thai', sans-serif;
          font-size: $size-16 !important;
          font-weight: 300 !important;
          line-height: 24px;
        }
      }
      .block-img-profile {
        width: 40px;
        height: 40px;
        svg {
          width: 24px !important;
          height: 24px !important;
        }
        @media (max-width: $screen-sm) {
          width: 40px;
          height: auto;
          .icon-profile {
            width: 32px;
          }
        }
      }
    }
  }
}
